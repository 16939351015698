.bottom {
  padding: 50px 0;
  background: #1b2a4e;

  .bottom-logo {
    p {
      // color: #b2b6c4;
      line-height: 30px;
      font-weight: 400;
    }
  }

  .bottom-widget {
    @include respond("tab-port") {
      margin-bottom: 30px;
    }

    .widget-title {
      font-size: 18px;
      font-weight: 600;
      color: #fff;
      margin-bottom: 20px;
    }

    ul {
      li {
        a, button {
          // color: #b2b6c4;
          color: $body-color;
          font-weight: 400;
          margin-bottom: 10px;
          display: inline-block;
        }
      }
    }
  }
}
