.modal-25w {
	min-width: calc(min(390px, 100%));
	width: 25%;
	max-width: none !important;
	margin: 0 auto;
}

.modal-50w {
	min-width: calc(min(390px, 100%));
	width: 50%;
	max-width: none !important;
	margin: 0 auto;
}

.modal-290px {
	width: 290px;
	max-width: none !important;
	margin: 0 auto;
}

.modal-400px {
	width: calc(100% - 1rem);
	max-width: 400px !important;
	margin: 1.75rem auto;
}

.modal-600px {
	width: calc(100% - 1rem);
	max-width: 600px !important;
	margin: 0 auto;
}

.modal-700px {
	width: calc(100% - 1rem);
	max-width: 700px !important;
	margin: 0 auto;
}

.modal-1140px {
	width: calc(100% - 1rem);
	max-width: 1140px !important;
	margin: 0 auto;
}

.height-520px {
	height: 520px !important;
	margin: 0 auto;
	@include respond('tab-port') {
		height: 100% !important;
	}
}

.widget-card {
	background: $gray-100;
	border-radius: 5px;
	margin-bottom: 15px;
	padding: 0px 20px;

	.widget-stat {
		min-width: 180px;
		padding: 20px 0;

		h5 {
			color: $body-color;
		}

		i {
			font-size: 24px;
			// color: #fff;
		}
	}
}

.profile_chart {
	&.card {
		width: 100%;

		.card-header {
			border: 0px;

			// padding-bottom: 0px;
			@include respond('phone-land') {
				flex-direction: column;
			}
		}

		.chart-content {
			.row {
				.chart-item {
					height: 105px;
					margin-bottom: 20px;
				}
			}
		}

		.card-body {
			&.quote-overview {
				display: flex;
				flex-wrap: wrap;
			}

			// padding: 10px 45px 30px;
			.chart_current_data {
				min-width: 165px;
				border-radius: 16px;
				background: $white;
				padding: 13px 20px;
				display: inline-block;
				margin-bottom: 40px;

				h3 {
					font-size: 36px;
					color: $primary;
					margin-bottom: 0px;
					line-height: 43px;
				}

				p {
					margin-bottom: 0px;
					font-size: 14px;
					font-weight: 500;
					color: $headings-color;
				}
			}
		}
	}
}

.stock-yield {
	position: relative;

	h3 {
		font-size: 1.2rem;
	}

	.stock-yield-title {
		display: flex;
		justify-content: flex-start;
		align-items: baseline;
	}
}

.chart-timeline {
	.hidden {
		visibility: hidden;
	}
}

.duration-option {
	box-shadow: 0px 36px 48px rgba(31, 66, 135, 0.04);
	text-align: center;

	a,
	button {
		display: inline-block;
		margin-left: 5px;
		text-align: center;
		padding: 5px 10px;
		border: 1px solid $border-color;
		// float: left;
		transition: all 0.3s ease;
		font-weight: 500;
		border-radius: 5px;
		font-size: small;

		@include respond('tab-port') {
			margin-left: 4px;
			padding: 4px;
		}

		// color: $white;
		&:hover,
		&:focus,
		&.active {
			background: $primary;
			color: $white;
			border-right: 1px solid transparent;
		}

		// &:first-child {
		//     border-top-left-radius: 25px;
		//     border-bottom-left-radius: 25px;
		// }
		// &:last-child {
		//     border: 0px;
		// }
	}
}

.area-chart-btn {
	button {
		min-width: 30px;
	}
}

.chart-stat {
	background: $gray-100;
	border-radius: 5px;
	padding: 15px 15px;
	margin-bottom: 15px;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	cursor: default;

	@include respond('tab-port') {
		cursor: pointer;
	}

	@include custommq($min: 1200px, $max: 1350px) {
		padding: 15px 7px;
	}
}

.transaction-table {
	td {
		padding: 1.25rem 0.75rem;
	}

	i.cc {
		font-size: 22px;
		margin-right: 10px;
	}

	.sold-thumb {
		background: $danger;
		color: $white;
		border-radius: 50px;
		width: 30px;
		height: 30px;
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;

		i {
			font-weight: 700;
			// font-size: 18px;
		}
	}

	.buy-thumb {
		background: $success;
		color: $white;
		border-radius: 5px;
		width: 30px;
		height: 30px;
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;

		i {
			font-weight: 700;
			// font-size: 22px;
		}
	}

	.badge {
		color: #fff;
	}
}

.balance-widget {
	ul {
		li {
			padding: 18px;
			align-items: center;
			border-bottom: 0px solid $border-color;
			background: $gray-100;
			border-radius: 5px;
			margin-bottom: 15px;

			i {
				font-size: 30px;
			}
		}
	}
}

.total-balance {
	padding: 50px 0 35px;
	text-align: center;
}

// Apps Download
.apps-download {
	background-image: url('./../images/app.png');
	background-color: #3a3361;
	background-size: cover;
	background-position: center;
	position: relative;
	z-index: 2;

	&::after {
		position: absolute;
		content: '';
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		background: #3a3361;
		opacity: 0.95;
		z-index: -1;
		border-radius: 5px;
	}

	// .card-title {
	//     color: $white;
	// }
}

.apps-download-content {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	min-height: 387px;

	li {
		color: $white;
		margin-bottom: 15px;
	}
}

.quote-main-stats {
	a {
		max-width: 100%;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

.portfolio-main-stats {
	display: grid;
	grid-template-columns: repeat(4, 150px);

	@include respond('tab-port') {
		grid-template-columns: repeat(2, 150px);
		gap: 0.5rem;
	}
}

.portfolio-main-stats-container {
	background-color: #fbfbfb;
	border-radius: 5px;
	font-weight: 600;

	&.home {
		background-color: transparent;
		display: flex;
		flex-direction: column;
		@include respond('tab-port') {
			width: fit-content;
			margin-left: 22.5%;
		}

		p,
		span {
			text-align: left;
		}
	}
}

.scroll {
	padding-top: 1rem;
	margin-bottom: 1rem;
	display: flex;
	overflow-x: auto;
	overflow-y: hidden;
	white-space: nowrap;
	line-height: 0;

	/* transform: translate3d(0, 0, 0);
	position: sticky; */
	cursor: grab;
}

.dashboard-home-avatar {
	border-radius: 50%;
	height: 128px;
	width: 128px;

	@include respond('tab-port') {
		margin-top: 0px;
		height: 48px;
		width: 48px;
	}
}
