.academy-unit-item {
	border-radius: 5px;
	background-color: $gray-200;
	> div {
		max-width: 370px;
	}
	@include respond('tab-port') {
		border-radius: 0px;
	}
}

.academy-unit-button {
	width: fit-content;
}

.academy-unit-art {
	max-width: 280px;
	mix-blend-mode: luminosity;
}

.academy-unit-progress {
	height: 15px;
	background: rgba(244, 245, 247, 0.4);
	border-radius: 35px;

	> div {
		top: 50%;
		left: 0;
		transform: translateY(-50%);
		height: 15px;
		border-radius: 35px;
		transition: width 0.3s cubic-bezier(0.23, 0.32, 0.98, 1.04);
	}
}

.academy-unit-modules {
	transition: max-height 0.3s cubic-bezier(0.14, 0.21, 0.85, 0.98);
}

.academy-unit-modules-path {
	position: absolute;
	top: 115px;
	left: 50%;
	transform: translateX(-50%);
	svg {
		height: 100%;
		width: 100%;
	}
}

.academy-quiz-sidebar {
	max-width: 100%;
	max-height: 60vh;
	overflow: auto;
	li {
		position: relative;
		background-color: lighten($primary, 45%);
		border-radius: 5px;
		&.done {
			background-color: $gray-200;
		}
		&.selected {
			background-color: $primary;
		}
		.lock {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(0, 0, 0, 0.4);
			svg {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
		}
	}
}

.academy-quiz-modal {
	z-index: 2;
	left: 0;
	bottom: 0;
	transform: translateY(150%);
	transition: transform 0.3s cubic-bezier(0.03, 0.6, 0.19, 1.25);
	&.open {
		transform: translateY(0%);
	}
	&:not(.good) {
		background: lighten($danger, 12%);
		color: darken($danger, 45%);
	}
	&.good {
		background: lighten($success, 40%);
		color: darken($success, 15%);
	}
}

.academy-quiz-modal-overlay {
	pointer-events: none;
	z-index: 1;
	opacity: 0;
	left: 0;
	top: 0;
	height: 100%;
	transition: opacity 0.5s cubic-bezier(0.03, 0.6, 0.19, 1.25);
	&.open {
		pointer-events: all;
		opacity: 0.4;
		transform: translateY(0%);
	}
	&:not(.good) {
		background: lighten($danger, 12%);
	}
	&.good {
		background: lighten($success, 40%);
	}
}

.academy-quiz-start-tooltip {
	&.show {
		z-index: 1;
		opacity: 1 !important;
	}
	.tooltip-inner {
		background: $primary;
		animation: start-tooltip-animation 1.3s ease-out infinite forwards;
	}
	.arrow {
		animation: start-tooltip-animation 1.3s ease-out infinite forwards;
		&::before {
			border-top-color: $primary;
		}
	}
}

@keyframes start-tooltip-animation {
	0% {
		margin-bottom: 0;
	}
	15% {
		margin-bottom: 3px;
	}
	60% {
		margin-bottom: -5px;
	}
	85% {
		margin-bottom: -5px;
	}
	100% {
		margin-bottom: 0px;
	}
}

.academy-module-label {
	background-color: $light;
	border-bottom-width: 2px;
	border-bottom-style: solid;
}

.academy-quiz-next {
	position: relative;
	&.inner {
		background-color: $gray-300 !important;
		color: $black !important;
		font-size: 16px !important;
	}
	&:hover {
		color: $black !important;
		background-color: $gray-400 !important;
	}
	&:active {
		color: $gray-200 !important;
	}
	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 0%;
		border-radius: 10px;
		background-color: rgba(0, 0, 0, 0.15);
	}
	&.animation {
		&::before {
			animation: academy-quiz-next-animation 3s ease-out 1 forwards;
		}
	}
}

@keyframes academy-quiz-next-animation {
	from {
		width: 0%;
	}
	to {
		width: 100%;
	}
}

.academy-quiz-next-cancel {
	&.inner {
		font-size: 16px !important;
	}
}

.academy-landing {
	margin-top: -15px;
	section {
		padding: 100px 0;
	}
}

.academy-landing-card {
	width: 100%;
	max-width: 288px;
	background-color: lighten($primary, 45%);
}

.academy-landing-road {
	max-width: 582px;
	margin: auto 0;
	// transform: rotateZ(-90deg);
}

.academy-landing-vector-background {
	display: flex;
	width: 56px;
	height: 56px;
	padding: 10px;
	border-radius: 50%;
	background: #fff;
	box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
}

.academy-landing-pricing {
	min-width: calc(min(100%, 350px));
	.card-body {
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}
}

.academy-landing-pricing-old {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(calc(-100% - 36px), calc(-70%));
	font-size: 18px;
	&::after {
		content: '';
		position: absolute;
		top: 52%;
		left: 0;
		transform: translateY(-50%);
		width: 100%;
		height: 1px;
		border-bottom: solid $body-color 1px;
		opacity: 0.8;
	}
}

.academy-landing-pricing-price {
	font-size: 36px;
	font-weight: bold;
}

.academy-auth-modal {
	max-width: 600px;
	background: $light;
}

.academy-landing-faq {
	.accordion {
		box-shadow: none !important;
	}
}

.tooltip[name='academy-green'] {
	&.show {
		opacity: 1;
	}
	.arrow::before {
		border-top-color: #009a4d;
	}
	.tooltip-inner {
		background-color: #009a4d;
	}
}

.tooltip[name='academy-blue'] {
	&.show {
		opacity: 1;
	}
	.arrow::before {
		border-top-color: #1652f0;
	}
	.tooltip-inner {
		background-color: #1652f0;
	}
}

.tooltip[name='academy-gold'] {
	&.show {
		opacity: 1;
	}
	.arrow::before {
		border-top-color: #834f00;
	}
	.tooltip-inner {
		background-color: #834f00;
	}
}

.academy-quiz-item-link {
	background: $light;
	svg {
		transition: transform 0.3s linear;
	}
	ellipse:nth-child(1) {
		transform-origin: center;
		transition: transform 0.3s linear;
	}
	&:hover {
		svg {
			transform: translateY(5px);
		}
		ellipse:nth-child(1) {
			transform: scale(0.95) translateY(-5px);
		}
	}
}

.home-academy {
	background-color: #FFFFFF;
	border-radius: 5px;
	padding: 35px 35px;
}

.home-academy-card {
	border-radius: 5px;
	transition: max-height 0.5s cubic-bezier(0.08, 0.2, 0.2, 1);
	background-color: rgba(225, 180, 92, 0.15);
	box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px 0px;
	width: 300px;

	.home-academy-for-you {
		color: $white;
		padding: 0.1rem 0.25rem;
		border-radius: 4px;
		background-color: $primary;
		box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.5);
		position: absolute;
		right: 1rem;
		top: 0.5rem;
	}

	.home-academy-card-badge {
		padding: 0.1rem 0.25rem;
		border-radius: 4px;
		background-color: #fff;
		box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.5);
		position: absolute;
		left: 1rem;
		top: 0.5rem;
	}

	&.primary {
		background-color: #fff;

		&.accent {
			background-color: lighten($primary, 45%);
		}
	}

	.fa {
		min-width: 12px;
	}

	&.hover {
	}
}

.home-academy-card-fix-height{
	height: 400px;
	.home-academy-card{
		width: 350px;
	
		.home-academy-card-thumbnail {
			height: 175px;
			max-height: 175px;
			background-repeat: no-repeat;
			background-size: auto 100%;
			background-position: center;
		}
	}
}

.home-academy-card-light {
	background-color: transparent;

	.home-academy-card-light-accent {
		background-color: rgba(225, 180, 92, 0.15);
		border-radius: 5px;
	}

	img {
		border-radius: 5px;
	}

	.progress-bar {
		background-color: #b0cfff;
	}
	.progress-bar-bg {
		background-color: #fafafa !important;
	}
}

.home-academy-card-thumbnail {
	height: 150px;
	position: relative;
	border-radius: 5px 5px 0 0;

	h4,h3 {
		position: absolute;
		bottom: 0px;
		margin: 0 0 0.5rem 1rem;
		color: #fff;
	}

	
	
	.home-academy-card-stats {
		position: absolute;
		right: 1rem;
		bottom: 0.5rem;
		display: flex;

		.home-academy-card-stat{
			display: flex;
			align-items: center;
			justify-content: center;
			margin-right: 0.5rem;
			&:last-child {
				margin-right: 0;
			}
			padding: 0.25rem;
			border-radius: 20px;
			background-color: $white;
			color: $black;

			img{
				width: 18px;
			}
		}
	}
}

.academy-you-will-item{
	border-radius: 5px;
	background-color: $gray-100;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0.5rem;
	height: 90px;
	p {
		color: $dark;
		margin: 0;
		font-weight: 700;
		line-height: 1.2;
	}
}

.academy-skill-preview {
	display: grid;
	grid-template-columns: auto 375px;

	> div {
		grid-column-start: 1;
		grid-column-end: 2;
	}
	> :nth-child(1) {
		grid-column-start: 1;
		grid-column-end: 2;
	}
	> :nth-child(2) {
		position: sticky;
		top: 79px;
		height: min-content;
		grid-row-start: 1;
		grid-row-end: 3;
		grid-column-start: 2;
		grid-column-end: 3;
	}

	@include respond('tab-port') {
		> div {
			grid-column-start: 1;
			grid-column-end: 3;
		}
		> :nth-child(1) {
			grid-column-start: 1;
			grid-column-end: 3;
		}
		> :nth-child(2) {
			position: initial;
			top: initial;
			grid-row-start: 3;
			grid-row-end: 4;
			grid-column-start: 1;
			grid-column-end: 3;
		}
	}
}
