
.career {
  .career-content-img {
    img {
      border-radius: 5px;
      // max-width: 90%;
    }
  }

  .career-content {
    h3 {
      font-weight: 700;
      margin-bottom: 15px;
    }

    p {
      line-height: 1.75;
    }
  }
}