.feed-insight-body{
  display: flex;
	flex-direction: row;
  
  @include respond("phone") {
    flex-direction: column;
  }
}
.feed-insight-text{
  color: $body-color;
  line-height: normal;
}

.feed-insight-logo{
  max-width: 30px;
  border-radius: 50px;
  margin-right: 7px;
  cursor: pointer;
}


.feed-insight-image{
  max-width: 120px; /* width of container */
  height: 100px; /* height of container */
  object-fit: cover;

  @include respond("phone") {
    max-width: 100%;
    height: 50%;
    margin-bottom: 1em;
  }
}

.insight-symbol{
  background-color: lighten($primary, 45%);
  cursor: pointer;
  border-radius: 5px;
  padding-right: 5px;
  padding-left: 5px;
}