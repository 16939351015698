.onboarding-container {
	height: 650px;
	width: 100%;
	@include respond('phone-land') {
		width: unset;
		height: 100%;
	}
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
}

.progress-bar,
.progress-bar-bg {
	height: 2px;
	transition: width 0.5s linear;
	&.lg {
		height: 8px;
	}
	&.xl {
		height: 15px;
	}
	&.left-radius {
		border-top-left-radius: 16px;
		border-bottom-left-radius: 16px;
	}
	&.right-radius {
		border-top-right-radius: 16px;
		border-bottom-right-radius: 16px;
	}
}

.progress-bar-bg {
	background-color: #d9d9d9;
}

.onboarding-text-area-container {
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;

	.text-area {
		width: 100%;
		outline: none;
		border: 1px solid $gray-500;
		resize: none; /* Disable manual resize */
		border-radius: 4px;
		padding: 10px;
		padding-bottom: 30px; /* Adds space for the counter */
		box-sizing: border-box; /* Ensures padding is included in the total height */
	}

	.counter {
		position: absolute;
		bottom: 5px; /* Adjust based on your padding */
		right: 10px; /* Adjust based on your padding */
		background-color: rgba(
			255,
			255,
			255,
			0.7
		); /* Semi-transparent background */
		font-size: 12px;
		padding: 2px 5px;
		border-radius: 5px;
	}
}

@keyframes draw {
	from {
		stroke-dasharray: 500;
		stroke-dashoffset: 500;
	}
	to {
		stroke-dashoffset: 0;
	}
}

@keyframes circle-fill {
	from {
		fill: #1652f0;
	}
	to {
		fill: #869ab8;
	}
}

.onboarding-text-blob {
	background: $gray-100;
	border: 2px solid $primary;
	padding: 15px;
	border-radius: 10px;
	color: $black;
}