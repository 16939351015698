.text-yellow {
	color: darken($yellow, 40%);
}

.text-blue {
	color: darken($blue, 20%);
}

.text-green {
	color: $green;
}

.text-black {
	color: $black;
}

.text-gold {
	color: $gold;
}

.text-primary {
	color: $primary;

}

.text-gray {
	color: $gray-400 !important;
}

.text-light-gold {
	color: #FFB400;
}

.text-gold {
	color: #E1B45C;
}

.text-body-style {
	color: $body-color !important;
	font-family: $font-family-base !important;
}

.h1-font-size{
	font-size: $h1-font-size;
}

.h2-font-size{
	font-size: $h2-font-size;
}