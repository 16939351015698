.sticky-header th {
	background-color: $primary !important;
	color: $white !important;
}

.screener-table-row {
	cursor: pointer;
}

.pre-made-screeners {
	overflow: auto;
	display: grid;
	grid-template-columns: repeat(4, minmax(300px, 1fr));

	&.mini {
		grid-template-columns: repeat(4, auto);
	}

	.discover-screener & {
		grid-template-columns: repeat(5, minmax(270px, auto));
	}

	.card-body {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		cursor: pointer;
		white-space: normal;
		line-height: normal;
		box-shadow: none;
		position: relative;
		background: $gray-100;
	}

	.premium {
		background: linear-gradient(
			240.26deg,
			lighten($gold, 15%) 19.76%,
			lighten($gold, 5%) 48.17%
		) !important;
		.logo {
			position: absolute;
			width: fit-content;
			right: 10px;
			bottom: 10px;
			color: $white;
		}
	}

	a {
		color: $body-color;
	}

	small {
		min-height: 41px;
	}
}
