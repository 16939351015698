.our_vision {
  h3 {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 28px;
  }

  p {
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 30px;
  }
}

.about {
  // background: $white;
}

.service-img {
  img {
    border-radius: 5px;
  }
}