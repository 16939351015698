.section-padding {
	padding: 100px 0px;
	@include respond('tab-land') {
		padding: 70px 0px;
	}
}

.direction-rtl {
	direction: rtl;
}

.direction-ltr {
	direction: ltr;
}

.section-border {
	border-bottom: 1px solid $border-color;
}

.section-title {
	margin-bottom: 75px;

	h2 {
		text-align: center;
		font-weight: 700;
	}
}

// Scrollbar
::-webkit-scrollbar {
	width: 10px;
	height: 10px;
}

/* Track */

::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px grey;
	border-radius: 10px;
}

/* Handle */

::-webkit-scrollbar-thumb {
	background: #999;
	border-radius: 10px;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
	background: #777;
}
