.widgets-carousel {
	.carousel-item {

		img {
			height: 300px;
			
			@include respond("phone") {
				height: 150px;	
			}
		}
		

	}

	.carousel-control-prev-icon {
		background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 fill=%27%231652F0%27 width=%278%27 height=%278%27 viewBox=%270 0 8 8%27%3e%3cpath d=%27M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z%27/%3e%3c/svg%3e");
	}

	.carousel-control-next-icon {
		background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 fill=%27%231652F0%27 width=%278%27 height=%278%27 viewBox=%270 0 8 8%27%3e%3cpath d=%27M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z%27/%3e%3c/svg%3e");
	}

	.carousel-indicators {

		li {
			background-color: $primary;
		}
	}
}

.onboarding-carousel {
	.carousel-item {
		text-align: center;

		img {
			height: 225px;
		}

		.carousel-caption {
			position: static;
			color: $black;
		}


	}

	.carousel-control-prev-icon {
		background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 fill=%27%231652F0%27 width=%278%27 height=%278%27 viewBox=%270 0 8 8%27%3e%3cpath d=%27M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z%27/%3e%3c/svg%3e");
	}

	.carousel-control-next-icon {
		background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 fill=%27%231652F0%27 width=%278%27 height=%278%27 viewBox=%270 0 8 8%27%3e%3cpath d=%27M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z%27/%3e%3c/svg%3e");
	}

	.carousel-indicators {

		li {
			background-color: $primary;
		}
	}

	a {
		margin-top: auto;
		height: 50px;
	}
}

.onboarding-progress {
	min-width: 280px;
	max-width: 500px;
	width: 70%;
	margin-bottom: 2rem;

	.onboarding-progress-item {
		display: flex;
		flex-direction: column;
		align-items: center;

		.onboarding-progress-bullet {
			background-color: $white;
			border-color: $primary;
			border-radius: 30px;
			height: 45px;
			width: 45px;
			padding: 10px;
			border-style: solid;
			border-width: medium;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-bottom: 0.5rem;

			h4 {
				color: $primary;
				text-align: center;
				font-weight: bold;
				margin-bottom: 0px !important;
			}
		}

		&.active {
			.onboarding-progress-bullet {
				background-color: $primary;

				h4 {
					color: $white;
				}
			}
		}
	}
}

.onboarding-next-btn {
	min-width: 50px !important;
	font-size: 0.8rem;
	margin: 0px !important;
	padding: 5px 15px !important;
}

.onboarding-picker-item {
	display: flex;
	justify-content: space-evenly;
	align-items: center;

	background: $gray-300;
	color: $gray-800;
	text-align: center;
	padding: 5px;
	margin-bottom: 0.5rem;
	border-radius: 5px;
	box-shadow: rgba(218, 225, 233, 0.557) 0px 3px 3px;
	font-weight: 600;
	cursor: pointer;

	&.onboarding-category {
		font-size: 0.9rem;
		padding: 5px;
		margin: 5px;
		color: $gray-600;

		img {
			height: 1rem;
			width: auto;
			margin-right: 6px;
		}

		i {
			font-size: 1rem;
			margin-right: 6px;
		}
	}

	&.onboarding-intention {
		height: 200px;
		width: 200px;
		font-size: 1rem;
		margin: 1rem !important;
		font-weight: 800;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 10px;

		input {
			width: 80%;
		}

		img {
			width: 100px;
			height: auto;
		}
	}

	&:hover {
		background: $gray-400;
	}

	&:active {
		transform: translateY(4px);
	}

	&.selected {
		background: lighten($success, 30%);

		&:hover {
			background: lighten($success, 40%);
		}
	}
}

.portfolio-card {

	.card-body {
		box-shadow: 0 2px 4px 0 rgba(0,0,0,.2);
	}

	.media {
		padding: 20px;
		padding-left: 10px;
		border-right: 1px solid $primary;

		i {
			color: $white;
			font-size: 42px;
			background: $primary;
			border-radius: 50%;
			padding: 10px;
		}
	}

	.bullets {
		margin-left: 20px;
		font-size: 14px;
	}

	&.widget {
		.card-body {
			border: 1px solid $gray-300;
		}

		&.selected {
			.card-body {
				border: 1px solid $primary;
				background-color: darken($gray-100, 5%);
			}
		}

		ul li i {
			font-weight: 700;
			// color: #1652F0;
			margin-right: 8px;
		}
	}
}

.onboarding-success {
	border: none;

	.onboarding-congrats-icon {
		margin-top: -50px;
		width: 50%;
		height: auto;
	}

	h2 {
		margin-top: 30px;
		font-size: 2rem;
	}
}

@keyframes companySwitch {
	0% {
		opacity: 1;
	}

	40% {
		opacity: 0;
	}

	50% {
		opacity: 0;
	}

	60% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}