.signup-wall {
	.filter-blur {
		filter: blur(3px);
		-o-filter: blur(3px);
		-ms-filter: blur(3px);
		-moz-filter: blur(3px);
		-webkit-filter: blur(3px);
		pointer-events: none;
	}

	.button-center {
		top: 50%;
		left: 50%;
		transform: translateY(-50%) translateX(-50%);
		z-index: 1;
	}
}

tr {
	&.filter-blur {
		filter: blur(3px);
		-o-filter: blur(3px);
		-ms-filter: blur(3px);
		-moz-filter: blur(3px);
		-webkit-filter: blur(3px);
		pointer-events: none;
	}
}

.button-top {
	top: calc(min(50%, 300px));
	left: 50%;
	transform: translateY(-50%) translateX(-50%);
	z-index: 1;
}
