.buy-sell-widget {
  .nav-tabs {
    margin-bottom: 30px;
    border: 0px;

    .nav-item {
      width: 45%;
      text-align: center;
      padding: 10px 10px;
      font-size: 18px;
      text-transform: uppercase;
      border: 0px;
      font-weight: 600;
      border-radius: 5px;
      background: transparent;
      border: 1px solid $border-color;
      margin: 0px 15px;

      &.active {
        background: $primary;
        color: white;
      }
    }
  }
}

.buyer-seller {
  .d-flex {
    @include respond("phone-land") {
      .buyer-info,
      .seller-info {
        padding: 10px;

        img {
          display: none;
        }
      }
    }
    @include respond("phone-land") {
      justify-content: flex-start;
    }
    @include respond("phone") {
      flex-direction: column;
      .seller-info {
        text-align: left !important;
      }
    }
  }
}

#fundumental-summary {
  margin-top: -15px;
  box-shadow: none;

  .card {
    margin-bottom: 0px;
    border: 0px solid $border-color;
    box-shadow: none;

    .card-header {
      border: 0px;
      padding: 15px 20px;
      
      @include respond("tab-port") {
        padding: 0.25rem 0.2rem !important;
      }
    }

    .card-body {
      padding: 15px 20px 10px;
    }
  }
}

.accordion-faq {

  .card {

    .card-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between !important;
    }

    .card-body {
      color: $black;
    }
  }
}

.intro-video-play {
  background-color: $white;
  background-image: url("./../images/background/2.jpg");
  background-size: cover;
  background-position: 0 0;
  min-height: 465px;
  // max-width: 800px;
  margin: 0 auto;
  position: relative;
  border-radius: 5px;
  z-index: 1;
  margin-bottom: 30px;
  box-shadow: 0 1.5rem 4rem rgba(22, 28, 45, 0.1);

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $primary;
    opacity: 0.45;
    border-radius: 5px;
    z-index: -1;
  }
}

.play-btn {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;

  span {
    background: $white;
    width: 75px;
    height: 75px;
    border-radius: 100px;
    padding: 27px 0;
    text-align: center;
    cursor: pointer;

    i {
      color: $primary;
    }
  }
}

.modal-video-close-btn {
  min-width: auto !important;
  padding: 0 !important;
  border-radius: 0px !important;
  font-size: 0px !important;
  font-weight: normal !important;
}
