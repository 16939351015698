.helpdesk-search {
  background: #F9F9F9;

  .helpdesk-search-content {
    text-align: center;
  }

  .helpdesk-form {
    form {
      display: flex;
    }

    .btn {
      margin-left: 20px;
      padding: 6px 30px;
    }
  }
}

.help-category {
  .help-cat {
    text-align: center;
    margin-bottom: 50px;

    span {
      font-size: 48px;
      color: $primary;
    }

    h4 {
      font-size: 20px;
      margin-top: 15px;
    }
  }

  .sub-cat {
    display: inline-block;
    padding: 20px 30px;
    background: lighten($primary, 45%);
    border-radius: 5px;
    text-align: center;
    margin-bottom: 30px;

    h5 {
      color: $primary;
      margin-bottom: 0px;
    }
  }
}

.troubleshooting-content {
  h4 {
    border-bottom: 1px solid $border-color;
    padding-bottom: 20px;
    margin-top: 50px;
  }

  ul {
    margin-top: 20px;

    li {
      a {
        color: $body-color;
        display: inline-block;
        padding-bottom: 10px;

        &:hover, &:focus, &:active, &.active {
          color: $primary;
        }
      }
    }
  }
}


.ticket-box-content {
  background: #ECEEEF;
  text-align: center;
  padding: 50px 15px;

  p {
    padding: 10px 0 20px;
    margin-bottom: 0px;
    font-size: 18px;
  }

  .btn {
    min-width: 150px;
    font-weight: 600;
  }
}