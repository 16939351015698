.tools-primary-widget{
    background-color:$primary !important;
    padding: 1rem;
    margin: 0px !important;
    h5, p, h4, h1, h2, h3, a{
        color: $white;
    }

    &.article-mode{
        background-color:$white !important;
        padding: 0rem;
        margin-left: -0.5rem !important;
        margin-right: -0.5rem !important;

        &.just-buttons{
            margin-left: 0rem !important;
            margin-right: 0rem !important;
        }

        .widget-row-header-text{
            margin-bottom: 0.25rem;
        }
        
        h5, h4, h1, h2, h3{
            color: $black;
        }

        a{
            color: $primary;
            // font-weight: bold;
        }
    }

    .widget-row-header-text{
        margin-bottom: 33px;
    }

    .tools-primary-widget-header{
        padding-right: 0.5rem !important;
        padding-left: 0.5rem !important;
    }

    .jika-white-logo{
        display: block;
        max-width:170px !important;
        @include respond('tab-port') {
            max-width:230px !important;
        }
        width: auto !important;
        height: auto !important;
        object-fit:initial !important;

        &.row-display{
            max-width:300px !important;
        }
    }

    img {
        width: 60px !important;
        height: 55px !important;
        object-fit: cover;
        border-radius: 5px;
        @include respond('tab-port') {
            width: 80px !important;
            height: 72px !important;
          }
    }


    li{
        background-color: lighten($color: $primary, $amount: 15%);
        border-radius: 10px;
        padding: 0.5rem !important;
        h5, p, h4, h1, h2, h3, a{
            color: $white !important;
        }
    }

}