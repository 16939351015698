.small-card-container {
	display: flex;
	justify-content: space-around;
	align-items: center;
	font-size: 10px;
	// max-width: 250px;
	max-height: 100px;
	min-height: 40px;
	background: $gray-100;
	text-align: center;
	padding: 5px;
	margin-bottom: 0.5rem;
	border-radius: 5px;
	cursor: pointer;

	img {
		width: 40px;
		height: 40px;
	}

	h5, h6 {
		margin-bottom: 0;
	}

	&:hover {
		background: $gray-200;
	}

	&:active {
		transform: translateY(4px);
	}

	&.selected {
		background: lighten($success, 30%);

		&:hover {
			background: lighten($success, 40%);
		}
	}
}

.small-card-link {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
}

.share-to-social {
	min-height: 270px;
	display: flex;
	flex-direction: column;
	position: fixed;
	top: 10rem;
	left: 3rem;
}

.share-to-social-mobile {
	min-width: 280px;
	min-height: 50px;
	max-width: 350px;
}
