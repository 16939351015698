.footer {
	border-top: 1px solid #203055;
	background: $dark-blue;
	padding: 27px 0px;

	&.dashboard {
		background: #f9f9f9;
		border-color: $border-color;

		@include respond('tab-port') {
			margin-left: 0px;
			padding: 27px 27px;
		}
	}

	.copyright {
		p {
			margin: 0;
			font-size: 14px;
			color: #7f88a6;

			a {
				color: $primary;
				font-weight: 700;
			}
		}
	}

	.footer-social {
		text-align: right;

		li {
			display: inline-block;

			a {
				color: #7f88a6;
				padding: 0px 10px;
			}
		}
	}

	.footer-headers {
		text-align: left;

		li {
			span {
				color: #7f88a6;
				font-weight: 700;
			}

			a {
				color: #7f88a6;
				padding: 0px 5px;
			}
		}
	}

	.social {
		a {
			display: inline-block;
			color: #7f88a6;
			padding: 0px 10px;
		}
	}
}

.demo-btn {
	color: #7f88a6;
	padding: 0 5px;
}