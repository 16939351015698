.save-my-spot-header {
	h1 {
		// font-weight: 400;
		line-height: 55px;
		font-size: 52px;
		@include respond('tab-port') {
			font-size: 46px;
		}
		@include respond('phone') {
			font-size: 42px;
		}
		// strong {
		//     font-weight: 500;
		// }
	}

	p {
		font-size: 32px;
		margin-bottom: 30px;
		margin-top: 30px;
		@include respond('tab-port') {
			font-size: 28px;
		}
		@include respond('phone') {
			font-size: 22px;
		}
	}

	.btn {
		color: $black;
		font-size: 24px;
	}
}

.save-spot-form {
	div {
		width: 50%;
		min-width: 250px;
		max-width: 500px;
		label {
			text-align: center;
			color: $white;
			line-height: 55px;
			font-size: 36px;
			@include respond('tab-port') {
				font-size: 32px;
			}
			@include respond('phone') {
				font-size: 26px;
			}
			input {
				box-shadow: 0 0 130px -60px $black;
			}
		}
	}
}
