.home-container {
	max-width: calc(min(1080px, 100%));
	display: grid;
	grid-template-columns: calc(100% - 421px);
	grid-template-rows: 370px 178px 80px;
	gap: 12px 16px;

	// Academy Start / Continue
	>:nth-child(1) {
		grid-area: 1 / 1 / 2 / 2;
	}

	// Portfolio
	>:nth-child(2) {
		grid-area: 1 / 2 / 3 / 3;
	}

	// Leagues
	>:nth-child(3) {
		grid-area: 3 / 2 / 5 / 3;
	}

	// Achievements
	>:nth-child(4) {
		grid-area: 2 / 1 / 4 / 2;
	}

	@include respond('tab-port') {
		grid-template-columns: 100%;
		grid-template-rows: auto;

		// Academy Start / Continue
		>:nth-child(1) {
			grid-area: 1 / 1 / 2 / 2;
		}

		// Portfolio
		>:nth-child(2) {
			grid-area: 2 / 1 / 3 / 2;
		}

		// Leagues
		>:nth-child(3) {
			grid-area: 3 / 1 / 4 / 2;
		}

		// Achievements
		>:nth-child(4) {
			grid-area: 4 / 1 / 5 / 2;
		}
	}

	.card {
		.card-header {
			padding: 18px;
			.card-title {
				font-size: 20px;
			}
		}
	}
}

.home-card-border {
	border: 1.5px solid rgba(229, 229, 229, 1) !important;
	border-radius: 20px;
}

.home-course-card {
	min-width: 207px;
	max-width: 207px;
	min-height: 270px;
	max-height: 270px;
	background-color: $white;
	box-shadow: 4px 4px 0 0 rgba(229, 229, 229, 1);

	&.disabled {
		cursor: default;
		border-radius: 20px;
		border: 1px solid #efefef !important;
		background: rgba(229, 229, 229, 1);
		box-shadow: none;
	}

	// @include respond('tab-port') {
	// 	min-width: 100%;
	// 	max-width: 100%;
	// }
}
