.profile_card {
  
  font-size: 0.9rem;
	@include respond('tab-port') {
    font-size: small;
    h3 {
      font-size: 1.35rem;
    }
	}
  .media {
    border-bottom: 1px solid $border-color;
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
  .top_sticker {
    position: absolute;
    width: 50px;
    height: 50px;

    text-align: center;
    font-size: 0.75rem;
    color: #ffb900;
    background-color: $white;
    border: 3px solid #ffb900;
    margin: 75px 0px 0px 75px;
    border-radius: 60px;
  }
}

.card-profile__info {
  h5 {
    display: inline-block;
  }

  img {
    height: 1rem;
  }
}

.sign-modal {
  .modal-body {
    .form-group {
      margin-bottom: 0.5rem;
      .form-control {
        height: 3rem;
      }
      label {
        margin-bottom: 0.25rem;
      }
    }
  }
  .small-card-container {
    width: 215px;
    justify-content: unset;
    &.animation {
			animation: companySwitch 1s cubic-bezier(.48,.86,.62,.16) 1;
		}
  }
}

.signup-modal {
  @include respond("desktop") {
    max-width: 60%;
  }
  @include respond("big-desktop") {
    max-width: 40%;
  }
}

.crossed {
  position: relative;
  font-size: 1.2rem;
}

.crossed:before {
  position: absolute;
  content: '';
  background: black;
  display: block;
  width: 100%;
  height: 5px;
  left: -10px;
  right: -10px;
  top: 0;
  bottom: 10px;
  margin: auto;
  transform: rotate(-20deg);
}

.social-icons {
  margin-top: 20px;

  a {
    border-radius: 100px;
    padding: 7px 0px;
    margin-right: 15px;
    height: 42px;
    width: 42px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: $white;
    background: $primary;
    text-align: center;
  }
}

.acc_balance {
  .btn-group {
    width: 100%;
  }
}

.paypal-button {
  max-width: 185px;
}

.linked_account {
  li {
    .row {
      align-items: center;
    }

    .media {
      border: 1px solid $border-color;
      align-items: center;
      padding: 20px;
      border-radius: 5px;

      & > span {
        background: $primary;
        color: $white;
        padding: 10px;
        border-radius: 50px;
        height: 50px;
        width: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px;
      }

      .media-body {
        p {
          margin-bottom: 0px;
        }
      }
    }

    .edit-option {
      a {
        display: inline-block;
        padding: 5px;
        font-size: 18px;

        &:last-child {
          color: $danger;
        }
      }
    }

    .verify {
      .verified,
      .not-verify {
        display: flex;
        align-items: center;
        font-weight: 500;

        span {
          background: $success;
          color: $white;
          padding: 10px;
          border-radius: 50px;
          height: 40px;
          width: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 5px;
          margin-right: 15px;
          font-weight: bold;
        }
      }

      .not-verify {
        span {
          background: $danger;
        }
      }
    }
  }
}

.profile-key-stats {
  @include respond("tab-port") {
    font-size: small;
  }
}
