/* Required Styling */
  
  .star-icon {
    cursor: pointer;
    width: 25px;
    height: 25px;
  }

  .star-icon-in-quote {
    cursor: pointer;
    width: 2.5em;
    height: 2.5em;
  }

  .yellow-star {
    color: #FFC309;
  }

  .watchlist-searchbox{
    padding-left:20px;
    max-width: 250px;
    width: 100%;
    width: -moz-available;          /* WebKit-based browsers will ignore this. */
    width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
    width: stretch;
  }

  .watchlist-picker-searchbox{
    // padding-left:20px;
    max-width: 250px;
    width: 100%;
    width: -moz-available;          /* WebKit-based browsers will ignore this. */
    width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
    width: stretch;
  }

  .watchlist-link{
    color:$body-color !important;
  }