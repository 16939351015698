.table-responsive-sm {
	width: 100%;
	width: -moz-available;
	/* WebKit-based browsers will ignore this. */
	width: -webkit-fill-available;
	/* Mozilla-based browsers will ignore this. */
	width: stretch;
}

.table {
	border-collapse: separate;
	border-spacing: 0 1rem;

	@include respond('tab-port') {
		font-size: 14px;
		border-collapse: separate;
		border-spacing: 0 0.25rem;
	}

	th,
	td {
		padding: 0.75rem;
		// color: $headings-color;
		font-weight: 600;

		@include respond('tab-port') {
			padding: 0.5rem;
		}
	}

	tr {
		td,
		th {
			background: $gray-100;
			border: 0px;
			vertical-align: middle;

			&:first-child {
				border-top-left-radius: 5px;
				border-bottom-left-radius: 5px;
			}

			&:last-child {
				border-top-right-radius: 5px;
				border-bottom-right-radius: 5px;
			}
		}
	}
}

.table-striped {
	tbody tr:nth-of-type(#{$table-striped-order}) {
		background-color: lighten($primary, 45%);
	}
}

.table-card {
	padding: 30px 0px !important;
}

.table-container {
	scroll-behavior: auto !important;
	font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
	font-size: 0.875rem;
	overflow: clip;
	position: relative;
	overscroll-behavior: contain;
}

.table-headers {
	scroll-behavior: auto !important;
	z-index: 2;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	overflow-x: auto;
	font-weight: 700;
	color: $black;

	&.sticky {
		position: -webkit-sticky;
		position: sticky;
		top: 64px;

		@include respond('tab-port') {
			top: 54px;
		}
	}
}

.table-headers-row {
	display: flex;
}

.table-headers-cell {
	padding: 0.5rem;
	min-width: 130px;
	display: flex;
	align-items: center;
	overflow: hidden;
}

.table-headers-cell {
	background-color: $white;
}

.table-headers-cell.blue-headers {
	background-color: $primary;
	color: $white;
}

.table-headers-cell.first-col {
	position: -webkit-sticky;
	position: sticky;
	left: 0;
	z-index: 2;
}

.table-headers-cell.second-col {
	position: -webkit-sticky;
	position: sticky;
	left: 175px;
	z-index: 1;
}

.table-headers-cell.last-col {
	position: -webkit-sticky;
	position: sticky;
	right: 0;
	z-index: 1;
}

.table-body {
	scroll-behavior: auto !important;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	overflow-x: auto;
	color: $black;
}

.table-body-row {
	display: flex;
}

.table-body-cell {
	padding: 0.5rem;
	min-width: 130px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	border-bottom: 1px solid $gray-100;
	background-color: $white;
}

.table-body-cell.first-col {
	position: -webkit-sticky;
	position: sticky;
	left: 0;
	z-index: 1;
}

.table-body-cell.second-col {
	position: -webkit-sticky;
	position: sticky;
	left: 175px;
	z-index: 1;
}

.table-body-cell.last-col {
	position: -webkit-sticky;
	position: sticky;
	right: 0;
	z-index: 1;
}

.gray-cell {
	background-color: $gray-100;
}
