.discussion {
  ul {
    width: 100%;

    li {
      border: 1px solid $border-color;
      padding: 0.5rem;
      margin-bottom: 32px;

      p {
        margin-top: 13px;
      }
    }
  }

  .cm-info-sec {
    padding: 0;
    border: 0;
    margin: 0;
    float: none;

    .cm-info {
      padding-top: 7px;

      h3 {
        margin-bottom: 3px;
      }

      h4 {
        color: $body-color;
        font-size: 14px;
        font-weight: 400;
      }
    }
  }

  .cm-action-sec {
    // border: 1px solid $border-color;
  }
}

.discussion-subcomment-wrapper {
  margin: 4%;
}

.discussion-comment-footer {
  width: 100%;
}

.discussion-comment-textarea {
  border: 0.5px solid #1652F0;
}

.discussion-comment-text {
  font-size: large;
  color: #212121;
}

.discussion-comment-input-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .rte {
    width: 100%;
    font-family: inherit !important;
    font-size: inherit !important;
  }

  button {
    min-width: inherit;
  }

  ul li {
    border: none;
    padding: 0;
    margin-bottom: 0;

    p {
      margin-top: 0;
    }
  }
}

.discussion-comment-input-button {
  margin: 5px 0px;
}