.quote-widget {

	// in the quote widget on mobile delete the margin on the top
	.card-body ul li:first-of-type {
		@include respond("phone") {
			margin-top: 0 !important;
		}
	}

	.media a {
		color: $body-color;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;

		h5 {
			@include respond("phone") {
				font-size: 17px;
			}
		}

		@include respond("phone") {
			flex-direction: column;
		}

		// h1:hover {
		// 	color: $headings-color;
		// }
	}

	.media img {
		height: 100px;
		border-radius: 5px;

		@include respond("phone") {
			width: 100%;
			height: 50%;
			margin-bottom: 1em;
		}
	}

	.meta-info {
		display: flex;
		flex-direction: row;

		@include respond("phone") {
			display: flex;
			width: 100;
			justify-content: space-between;
			margin-top: 1em;
		}

		a {
			font-size: 14px;
			color: $body-color;
			margin-right: 5px;
			display: flex;
			flex-direction: row;

			i {
				margin-top: 4.5px;
				margin-right: 4px;
			}

			@include respond("phone") {
				&:nth-of-type(1) {
					margin-left: 0.5em;
				}

				&:last-of-type {
					margin-right: 1em;
				}
			}

			&:nth-of-type(1) {
				margin-right: 0.5em;
			}
		}
	}

	.fixed-table {
		position: relative;
		overflow: auto;
	}
}

.accordion {

	.card {
		.card-header {
			i {
				margin-right: 15px;
			}

			cursor: pointer;
			justify-content: unset;
			align-items: unset;
			border-top-left-radius: 15px;
			border-top-right-radius: 15px;
		}

		.card-body {
			border-top-left-radius: 0px;
			border-top-right-radius: 0px;
		}
	}
}

#similar-companies-widget {
	img {
		width: 100%;
		max-width: 80px;
		margin: 10px;
	}
}