.jika-demo {
	width: 100%;
	height: 300px;
	padding-bottom: 200px;
	position: relative;
	background-color: darken($primary, 10%);
	iframe {
		box-shadow: 0 0 130px -60px $black;
		position: absolute;
		top: 50px;
		left: 50%;
		transform: translateX(-50%);
		max-width: 90%;
		@include respond('tab-port') {
			height: 450px;
		}
		@include respond('phone-land') {
			height: 350px;
		}
		@include respond('phone') {
			height: 250px;
		}
	}
	@include respond('tab-port') {
		height: 150px;
		padding-bottom: 150px;
	}
	@include respond('phone') {
		max-height: 0;
		padding-bottom: 0;
	}
}

.product-hunt-header {
	background-color: darken($primary, 10%);
	color: $white;

	.col-12 {
		background-color: darken($primary, 10%);
	}

	h1 {
		// font-weight: 400;
		line-height: 55px;
		font-size: 52px;
		// strong {
		//     font-weight: 500;
		// }
	}

	p {
		font-size: 32px;
		margin-bottom: 30px;
		margin-top: 30px;
	}

	.btn {
		color: $black;
		font-size: 24px;
	}
}

.btn-ph {
	font-size: 24px;
}

.after-breaker {
	margin-top: 150px;
	@include respond('tab-port') {
		margin-top: 100px;
	}
	@include respond('phone') {
		margin-top: 85px;
	}
}
