.result {
  .success,
  .failure {
    display: flex;
    align-items: center;
    font-weight: 500;

    span {
      background: $success;
      color: $white;
      padding: 10px;
      border-radius: 50px;
      height: 40px;
      width: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px;
      margin-right: 15px;
      font-weight: bold;
    }
  }

  .failure {
    span {
      background: $danger;
    }
  }
}