.group-company-preview {
	// border-top: 1px solid $gray-300;
	// border-bottom: 1px solid $gray-300;
	min-height: 100%;
	max-height: 100%;

	@include respond('tab-port') {
		border-top: none;
		border-bottom: none;
		// min-height: 0;
		// max-height: 0;
	}
}

.csat-rating-item{
	width: 27px;
	height: 36px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 18px;
	font-weight: bold;
	color: $white;
	border-radius: 2px;
	margin: 0 1px;

	&.left{
		border-top-left-radius: 50%;
		border-bottom-left-radius: 50%;
		width: 32px;
	}

	&.right{
		border-top-right-radius: 50%;
		border-bottom-right-radius: 50%;
		width: 32px;
	}

	&.selected{
		width: 42px;
		height: 48px;
		font-size: 24px;
	}
}

.csat-improvement-item{
	background-color: $white;
	border-radius: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px solid $gray-300;
	color: $body-color;
	&.selected{
		border: 1px solid $primary;
		color: $primary;
		font-weight: bold;
	}
}

.full-width {
	width: 100%;
	width: -moz-available;
	/* WebKit-based browsers will ignore this. */
	width: -webkit-fill-available;
	/* Mozilla-based browsers will ignore this. */
	width: stretch;
}

.line-input {
	flex-grow: 1;
	border: none;
	outline: none;
	background-color: transparent;
	border-bottom: 1px solid $dark;
	min-width: 100px;
	resize: none; /* Disable manual resize */
	height: auto;
	overflow-y: hidden;
}

.top-image-mobile-modal {
	margin-top: -36px;
	border-top-left-radius: 15px;
	width: 100vw;
	border-top-right-radius: 15px;
}

.sticky-to-header {
	// max-width => (home page max-width/4)-padding = 1140/4 - 15 = 270.
	max-width: 270px;
	position: fixed;
	top: 110px;
	padding-right: 15px;
	margin-right: auto;
	border-radius: 5px;
}

.min-width-0 {
	min-width: 0 !important;
}

.min-height-0 {
	min-height: 0 !important;
}

.text-small {
	font-size: small;
}

.text-strong {
	font-weight: bold;
}

.modal-custom-close-button {
	width: 100%;
	z-index: 99;
	position: relative;

	button {
		position: absolute;
		top: 25px;
		right: 20px;
		padding: 0.25rem 0.5rem 0 0 !important;
	}
}

.modal-close-button {
	position: absolute;
	right: 10px;
	top: 10px;
	color: black;
	z-index: 100;
}

.active-period {
	color: black;
	font-weight: bold;

	.discount-sign {
		font-weight: bold;
	}
}

.user-list-modal {
	max-height: 70vh;

	@include respond('tab-port') {
		max-height: 100vh !important;
	}
}

.discount-sign {
	width: 70px;
}

.row {
	margin-left: 0;
	margin-right: 0;
}

.mx-minus-1 {
	margin-left: -15px;
	margin-right: -15px;
}

.text-underline {
	text-decoration: underline !important;
}

.text-underline-on-hover:hover {
	text-decoration: underline !important;
}

.selected-row {
	background-color: lighten($color: $primary, $amount: 40%);

	:hover {
		background-color: lighten($color: $primary, $amount: 40%);
	}
}

.no-scroll-bar {
	-ms-overflow-style: none;
	overflow: -moz-scrollbars-none;
	scrollbar-width: none;
}

.no-scroll-bar::-webkit-scrollbar {
	display: none;
	width: 0;
	background: transparent;
}

.custom-scroll-bar {
	-ms-overflow-style: none;
	overflow: -moz-scrollbars-none;
	scrollbar-width: none;
}

.custom-scroll-bar::-webkit-scrollbar {
	width: 7px;
}

.custom-scroll-bar::-webkit-scrollbar-track {
	display: none;
	background: transparent;
}

.custom-scroll-bar::-webkit-scrollbar-thumb {
	background: $gray-400;
	border-radius: 5px;
}

.base-tab {
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	overflow: hidden;
	white-space: nowrap;
	color: $primary;

	&.btn {
		padding: 10px 20px;
	}
}

.paywall-card {
	width: calc(100% - 4rem);
	max-width: 400px !important;
	padding: 1rem !important;
}

.achievement-stage-item {
	:hover {
		background-color: $gray-100;
		border-radius: 10px;
	}
}

.post-title-truncate {
	max-height: 4rem;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.post-content-truncate {
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	text-overflow: ellipsis;
	overflow: hidden;
}

.base-tab:hover,
.base-tab:focus {
	color: $primary;
}

.tab-indicator-underline {
	position: absolute;
	bottom: 0;
	border-bottom: 3px solid $primary !important;
	border-radius: 0;
	transition: left 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.tab-indicator-pill {
	position: absolute;
	bottom: 0;
	background-color: $primary !important;
	border-radius: 5px;
	transition: left 0.5s cubic-bezier(0.165, 0.84, 0.44, 1),
		background 0.7s cubic-bezier(0.165, 0.84, 0.44, 1);
	z-index: -1;
}

.tab-indicator-gray-pill {
	position: absolute;
	bottom: 0;
	background-color: lighten($primary, 45%) !important;
	border-radius: 100px;
	transition: left 0.5s cubic-bezier(0.165, 0.84, 0.44, 1),
		background 0.7s cubic-bezier(0.165, 0.84, 0.44, 1);
	z-index: -1;
}

.tab-active-underline {
	border-bottom: 3px solid $primary !important;
	border-radius: 0;
}

.tab-active-tabs-pill {
	color: $white !important;
}

.tab-active-pill {
	background-color: $primary !important;
	color: $white !important;
}

.leaflet-container {
	height: 500px;
}

.light-gray-box {
	text-align: center;
	padding: 1rem;
	display: flex;
	flex-direction: column;
	border-radius: 5px;
	background-color: $gray-100 !important;
}

.gray-box {
	text-align: center;
	padding: 1rem;
	display: flex;
	flex-direction: column;
	border-radius: 5px !important;
	background-color: $gray-200 !important;
}

.apexchartsdividendxpiexchart,
.apexchartsdividend-pie-chart {
	svg {
		transform: none !important;
	}
}

.feed-item-like-button:hover {
	i {
		color: $primary !important;
	}
}

.delete-new-feed-item-dropdown::after {
	display: none;
}

.modal-dialog.create-post {
	margin: calc(min(25vh, 100px)) auto 0 auto !important;
	max-width: calc(max(50%, 800px)) !important;
}

.modal-dialog.signup-success {
	transition: transform 0.6s ease-out !important;
}

.modal-dialog.overflow-visible {
	.modal-content,
	.modal-body {
		overflow: visible;
	}
}

.create-post-title {
	z-index: 99;
}

.modal-dialog {
	.modal-content {
		.close {
			padding: 1rem 1.2rem;
		}
	}
}

.fullscreen {
	margin: 0 !important;
	max-width: 100% !important;
	width: 100% !important;
	height: 100vh;
	max-height: -moz-available;
	/* WebKit-based browsers will ignore this. */
	max-height: -webkit-fill-available !important;
	/* Mozilla-based browsers will ignore this. */
	max-height: stretch;
}

.modal-dialog.fullscreen {
	margin: 0 !important;
	max-width: 100% !important;
	width: 100% !important;
	height: 100vh;
	max-height: -moz-available;
	/* WebKit-based browsers will ignore this. */
	max-height: -webkit-fill-available !important;
	/* Mozilla-based browsers will ignore this. */
	max-height: stretch;

	&.with-padding {
		padding: 10vh;
		max-height: calc(100vh - 20vh) !important;

		.modal-content {
			max-height: calc(100vh - 20vh) !important;
		}

		@include respond('tab-port') {
			padding: 0;
			max-height: 100vh !important;

			.modal-content {
				max-height: 100vh !important;
			}
		}
	}

	.modal-content {
		border-radius: 0px;
		max-width: 100% !important;
		width: 100% !important;
		height: 100vh;
		max-height: -moz-available;
		/* WebKit-based browsers will ignore this. */
		max-height: -webkit-fill-available !important;
		/* Mozilla-based browsers will ignore this. */
		max-height: stretch;
		text-align: center;

		.close {
			z-index: 999;
			font-size: 3rem;
			margin: 0 0.5rem 0.5rem 1.5rem;
			padding: 0;
			position: absolute;
			right: 0;
		}
	}
}

.divider {
	display: block;
	text-align: center;
	overflow: hidden;
	white-space: nowrap;

	span {
		position: relative;
		display: inline-block;
	}

	span:before,
	span:after {
		content: '';
		position: absolute;
		top: 50%;
		width: 9999px;
		height: 1px;
		background: $border-color;
	}

	span:before {
		right: 100%;
		margin-right: 15px;
	}

	span:after {
		left: 100%;
		margin-left: 15px;
	}
}

.modal-lg-padding {
	padding-left: 15vw;
	padding-right: 15vw;
	max-width: 1000px;

	@include respond('tab-port') {
		padding-left: 5vw;
		padding-right: 5vw;
	}
}

.css-26l3qy-menu {
	min-width: 250px !important;
	border-color: gray !important;
}

.profile-portfolio-item-popup {
	background-color: $white;
	border: 1px solid $gray-300;
	box-shadow: 0 0 3px 1px $gray-300;
	border-radius: 5px;
}

.pointer-events-none {
	pointer-events: none;
}

.user-performance,
.user-label {
	font-family: Nunito;
	height: 16px;
	border-radius: 3.5px;
	font-size: 12px;
	line-height: initial;
	width: min-content;
	padding: 0 0.25rem;
	font-weight: 600 !important;

	&.large {
		height: 20px;
		font-size: 18px;
		margin-left: 0;
	}
}

.user-performance {
	border: 1px solid lighten($color: $primary, $amount: 15%);
	color: lighten($color: $primary, $amount: 15%);
	> svg {
		fill: lighten($color: $primary, $amount: 15%);
	}
	&.gold {
		border: 1px solid #e1b45c;
		color: #e1b45c;
	}
}

.user-label {
	background-color: lighten($color: $primary, $amount: 15%);
	color: $light;
	min-width: 50px;

	&.rookie {
		background: transparent;
		border: solid 1px $gray-500;
		color: $gray-500;
	}

	&.platinum {
		background: linear-gradient(180deg, #75d3e2 12.92%, #35bed4 42.19%);
	}

	&.gold {
		background: linear-gradient(180deg, #e1b45c 12.92%, #d49535 42.19%);
	}

	&.silver {
		background: linear-gradient(180deg, #adadad 12.92%, #8b8b8b 42.19%);
	}

	&.bronze {
		background: linear-gradient(180deg, #cd7f32 12.92%, #b84a1d 42.19%);
	}

	&.large {
		height: 20px;
		font-size: 18px;
		min-width: 100px;
		margin-left: 0;
	}
}

.join-conversation-wigdet {
	.card-body {
		background-color: $primary;
		color: $white;
	}
}

.fade.modal.show {
	padding: 0 !important;
}

.user-widget {
	max-width: 340px;
	padding: 0px;
	margin: 0px;
	border: none;
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 90vh;
	overflow-y: auto;
	overflow-x: hidden;
	position: fixed;
	background-color: $white;
	top: 98px;
	z-index: 15;
	left: 0;
	transition: left 0.6s ease-in-out;
	box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);

	@include respond('tab-port') {
		top: 54px;
	}

	&.user-widget-collapsed {
		left: -360px;
	}
	.card {
		margin-bottom: 0px;
		.card-body {
			border: 1px $border-color solid;
			padding: 15px 15px;
		}
	}
}

.logo-container {
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	overflow: hidden;
	background-color: $gray-300;

	img {
		padding: 20%;
		width: 100%;
		height: auto;
	}
}

.big-logo-render {
	width: 60px;
	height: 60px;

	@include respond('tab-port') {
		width: 40px;
		height: 40px;
	}
}

.plaid-info-item {
	i {
		font-size: 20px;
	}

	h5 {
		font-size: large;

		@include respond('tab-port') {
			font-size: initial;
		}
	}

	p {
		@include respond('tab-port') {
			font-size: small;
		}
	}
}

.plaid-modal-background {
	background-image: url('../images/plaid-modal-background.svg');
	background-size: cover;
	color: $white;
}

.styled-input {
	position: relative;
	cursor: text;
	border: 1px solid #ced4da;
	border-radius: 0.25rem;

	.DraftEditor-root {
		margin: 0;
		padding: 5px 22px;
		color: #506690;

		.public-DraftStyleDefault-block {
			width: fit-content;
			max-width: 100%;

			span,
			a {
				max-width: 100%;
				white-space: wrap;
			}
		}
	}

	&.title-input {
		z-index: 96;
		border: none;
	}

	p {
		margin: 0;
		padding: 5px 22px;
		color: #506690;
		width: fit-content;

		div {
			width: fit-content;
		}
	}
}

.DraftEditor-root {
	.image {
		max-width: 100%;
		border-radius: 5px;
	}

	.video {
		max-width: 100%;
		width: 700px;
		height: 350px;

		@include respond('tab-port') {
			width: 400px;
			height: 200px;
		}
	}
}

.red-white-icon {
	color: red;
	background-image: radial-gradient(
		circle closest-side,
		white 0%,
		white 30%,
		transparent 30%
	);
	background-position: center center;
	background-repeat: no-repeat;
	background-size: 100% 100%;
}

.styled-input:hover {
	border-color: #b3b3b3;
}

.styled-input:focus-within {
	border-color: #1652f0;
}

.auto-search-box .select__control {
	pointer-events: none;
	cursor: text;
	visibility: hidden;
	font-size: 0;
}

.mention-tag {
	background-color: lighten($color: $primary, $amount: 40%);
	border-radius: 5px;
	padding-left: 0.25rem;
	padding: 0.1rem 0.25rem;
}

.rich-editor-toolbar {
	z-index: 98;
	position: sticky;
	top: 0;
	left: 0;
	padding: 0;
	margin: 0;
	display: flex;
	background-color: $gray-200;

	li {
		cursor: pointer;
		pointer-events: none;
		color: $black;
		width: 30px;
		height: 30px;
		margin: 0.1rem;

		i,
		span {
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			pointer-events: all;
		}
	}

	li:hover,
	li.active {
		background-color: $gray-400;
	}

	.image-input {
		border: none;
		background: transparent;
	}
}

.mention-suggestions {
	z-index: 97;
	padding: 5px 0;
	background: $white;
	border-radius: 5px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
	max-height: 200px;
	overflow-y: auto;
}

.rich-search-entry {
	display: flex;
	align-items: center;
	width: fit-content;
	border-radius: 5px;
	padding: 0.25rem;
	margin: 0 0.25rem;
	min-width: 0;
	transition: background-color 0.4s cubic-bezier(0.27, 1.27, 0.48, 0.56);

	&.active {
		background-color: #cce7ff;
	}

	&:focus {
		background-color: #e6f3ff;
	}
}

.link-creator {
	padding: 5px;
	pointer-events: all;
	position: absolute;
	top: 30px;
	left: 68px;
	background-color: $gray-400;
	display: flex;
	flex-direction: column;

	label {
		font-size: 14px;

		input {
			height: 35px;
			padding: 2px 10px;
			background-color: $white;
		}
	}

	button {
		width: 50px !important;
		padding: 5px 15px;
		margin-left: auto;
	}
}

.input-link {
	&:hover {
		opacity: 0.8;
	}
}

.connect-portfolio-modal {
	min-width: calc(max(50%, 990px));

	.modal-content {
		overflow: hidden;

		.modal-body {
			overflow: hidden;

			div {
				overflow: hidden;

				div {
					overflow: hidden;
				}
			}
		}
	}
}

.recent-discussions-widget {
	color: $white;
	box-sizing: border-box;
	min-width: 370px;
	max-width: 370px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	background: linear-gradient(
		240.26deg,
		#6398ff 19.76%,
		#3e7fff 48.17%
	) !important;

	.btn-white {
		color: #0039a7;
	}

	.btn-link,
	p,
	h4 {
		color: $white;
	}
}

.minimized-title {
	div {
		text-overflow: ellipsis !important;
		overflow: hidden !important;
		white-space: nowrap !important;
		width: 200px;
	}
}

.social-widget-title {
	font-size: 1rem;
}

.mobile-modal-container {
	visibility: hidden;
	z-index: 9999;
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);

	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		background-color: transparent;
		transition: inherit;
	}

	&.show {
		visibility: visible;

		&::before {
			background-color: rgba(0, 0, 0, 0.2);
		}
	}
}

.mobile-modal {
	overflow: hidden !important;
	position: absolute;
	width: 100%;
	bottom: 0;
	background-color: $white;
	height: auto;
	max-height: calc(100%);
	transition: all 1s cubic-bezier(0.165, 0.84, 0.44, 1);
	border-radius: 20px 20px 0 0 !important;

	&.collapsed {
		transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
		max-height: 0;
	}

	.close-button {
		position: absolute;
		top: 7px;
		right: 15px;
		font-size: 24px;
		font-weight: 700;
		z-index: 990;
	}
}

.analayze-social-widget > div:not(:first-child) {
	margin-left: 1rem;
}

.apexcharts-xaxis-label {
	font-weight: 400;
}

.new-feed-transaction-title {
	width: fit-content;
	position: relative;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	justify-content: center;
	border-radius: 5px;
	padding: 5px;
	background-color: $gray-100;

	a {
		padding-inline: 0.5rem;
	}

	> span:last-child {
		padding-left: 0.5rem;
	}

	::after {
		content: '';
		width: 5px;
		height: 100%;
		position: absolute;
		bottom: 0;
		left: 0;
	}

	li {
		border: 0.5px solid $gray-400;
		border-radius: 5px;

		&:nth-child(2) {
			margin-left: 0.75rem;
			margin-right: 0.75rem;
		}
	}
}

.input-invalid-requirements {
	position: absolute;
	bottom: 25px;
	right: 0;
	transform: translate(-50%, 50%);

	&.small {
		bottom: 17.5px;
	}

	li {
		list-style: disc;
	}
}

.heatmap-cell:hover {
	opacity: 0.8;
}

.slider-mark {
	z-index: 2;
	&.upside {
		top: 45px !important;

		&::before {
			z-index: 1;
			bottom: 100% !important;
		}
	}
}

.ai-chat-modal {
	position: fixed;
	right: 10px;
	bottom: 10px;
	border-radius: 10px;
	background-color: #fff4e1;
	z-index: 9999;
	height: 600px;
	width: 400px;
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
	overflow-y: auto;

	@include respond('tab-port') {
		height: calc(100% - 100px);
		width: calc(100% - 40px);
	}

	.ai-chat-modal-messages {
		height: calc(100% - 100px);
		overflow-y: auto;
		display: flex;
		flex-direction: column-reverse;
		margin: 10px;
	}

	.ai-chat-modal-message {
		padding: 5px;
		margin: 10px;
		background-color: $white;
		border-radius: 5px;

		p {
			margin: 0;
		}
	}

	.ai-chat-modal-header {
		height: 50px;
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
		background-color: $white;
		border-bottom: 1px solid $gray-300;
	}
}

.tour-mask {
	opacity: 0.2 !important;
}

.images-preview {
	width: fit-content;

	button.active,
	button:hover {
		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(0, 0, 0, 0.3);
		}
	}
}

.progress-circle {
	position: relative;
	> svg {
		width: 210px;
		height: 210px;
		transform: rotate(-90deg);

		circle {
			width: 100%;
			height: 100%;
			stroke-width: 10;
			stroke-linecap: round;

			&:last-of-type {
				stroke-dasharray: 625px;
				stroke-dashoffset: calc(625px - (625px * var(--percent)) / 100);
			}
		}
	}
	&.small {
		> svg {
			width: 45px;
			height: 45px;

			circle {
				stroke-width: 3;

				&:last-of-type {
					stroke-dasharray: 125px;
					stroke-dashoffset: calc(125px - (125px * var(--percent)) / 100);
				}
			}
		}
	}
	p {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}

.ranks-list {
	width: 290px;
	height: 142px;

	> div {
		top: 0;
		height: 100px;
		transition: transform 0.35s cubic-bezier(0.14, 0.35, 0.67, 1);
	}
}

.rank-logo {
	perspective: 50px;

	position: relative;
	z-index: 2;
	width: 36px;
	height: 43px;
	transition: height 0.35s cubic-bezier(0.14, 0.35, 0.67, 1),
		width 0.35s cubic-bezier(0.14, 0.35, 0.67, 1);

	img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		&.animate-out {
			animation: custom-tada 1s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.5s 1
				forwards;
		}
	}

	&.small {
		width: 24px;
		height: 28px;
	}

	&.large {
		width: 64px;
		height: 72px;
	}
}

.rank-label {
	perspective: 50px;

	position: relative;
	z-index: 2;
}

@keyframes custom-tada {
	from {
		transform: rotate3d(0, 0, 0, 0deg);
	}

	10%,
	20% {
		transform: rotate3d(0, 0, 1, -3deg);
	}

	30%,
	50%,
	70%,
	90% {
		transform: rotate3d(0, 0, 1, 3deg);
	}

	40%,
	60%,
	80% {
		transform: rotate3d(0, 0, 1, -3deg);
	}

	to {
		transform: rotate3d(0, 0, 0, 0deg);
	}
}

.level-logo {
	perspective: 500px;
	-webkit-font-smoothing: subpixel-antialiased;

	&.animate-in {
		div:first-of-type {
			zoom: 2;
			-webkit-transform: scale(0.5);
			transform: scale(0.5);
			animation: level-up-animation var(--animation-time, 0.5s)
				cubic-bezier(0.77, 0.35, 0.76, 0.76) 1 forwards;
		}
	}
}

@keyframes level-up-animation {
	0% {
		-webkit-transform: translate3d(0, 0, 0) rotate3d(0, 0, 0, 0) scale(0.5);
		transform: translate3d(0, 0, 0) rotate3d(0, 0, 0, 0) scale(0.5);
	}
	40% {
		-webkit-transform: translate3d(0, 0, 0) rotate3d(0, 0, 1, 2deg) scale(0.5);
		transform: translate3d(0, 0, 50px) rotate3d(0, 0, 1, 2deg) scale(0.5);
	}
	60% {
		-webkit-transform: translate3d(0, 0, 0) rotate3d(0, 0, 1, -2deg) scale(0.5);
		transform: translate3d(0, 0, 50px) rotate3d(0, 0, 1, -2deg) scale(0.5);
	}
	100% {
		-webkit-transform: translate3d(0, 0, 0) rotate3d(0, 0, 1, 0) scale(0.5);
		transform: translate3d(0, 0, 0) rotate3d(0, 0, 1, 0) scale(0.5);
	}
}

.level-logo-progress-bar {
	width: var(--progress-start);
	animation: level-up-progress-animation var(--animation-time, 0.5s)
		cubic-bezier(0.77, 0.35, 0.76, 0.76) 1 forwards;
}

@keyframes level-up-progress-animation {
	from {
		width: var(--progress-start);
	}
	to {
		width: var(--progress-end);
	}
}

/* width */
::-webkit-scrollbar {
	box-sizing: border-box;
	width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
	box-shadow: none;
	background: $white;
	border-radius: 10px;
	// box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.2);
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: $gray-300;
	border-radius: 10px;
	outline: 1px solid white;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: $gray-400;
}

.dropdown-select__control.dropdown-select__control--is-focused {
	font-size: 16px !important;
}
