#___reactour {
  [data-tour-elem='controls'] {
    justify-content: center;
    @include respond('phone-land') {
      margin-top: 12px;
    }
  }
  .reactour__helper {
    @include respond('phone-land') {
      padding: 12px 15px;
    }
  }
}