.card {
	border: 0px;
	margin-bottom: 15px;
	border-radius: 5px;
	background: transparent;

	@include respond('tab-port') {
		border-radius: 0px;
		margin-bottom: 20px;
	}

	&-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-bottom: 0px;
		background: transparent;
		padding: 20 0px;
		// margin      : 0px 35px;
		@include respond('desktop') {
			padding: 20px;
		}
		// @include custommq($min: 1200px, $max: 1350px) {
		//   padding: 30px 0px;
		// }
		@include respond('tab-port') {
			padding: 20px;
		}
	}

	&-title {
		font-size: 24px;
		margin-bottom: 0px;
		// color: #ffffff;
		font-weight: 600;

		@include respond('tab-port') {
			font-size: 18px !important;
		}
	}

	&-main-title {
		font-size: 32px;
		margin-bottom: 0px;
		// color: #ffffff;
		font-weight: 700;
		text-shadow: -1px 1px 1px rgba(0, 0, 0, 0.2);
		@include respond('tab-port') {
			font-size: 24px;
		}
	}

	.card-body {
		padding: 20px 10px;
		background: $white;
		border-radius: 20px;
		border: 1.5px solid rgba(229, 229, 229, 1);
		// box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
		@include respond('desktop') {
			padding: 20px;
		}
		// @include custommq($min: 1200px, $max: 1350px) {
		//   padding: 30px 0px !important;
		// }
		@include respond('tab-port') {
			padding: 15px 5px;
		}
	}

}

.card-divider {
	border-bottom: 2px solid $border-color;
}

.fix-shadow-card-image {
	img {
		width: initial !important;
	}
}

.shadow-card-loader {
	min-height: 150px;
}

.card-blue {
	justify-content: space-between;
	background-color: $primary;
	padding: 15px 0px 30px;
	min-height: 250px;
	height: 250px;
	border-radius: 5px;
	.card-title {
		color: $white;
	}
}

.card-gray,
.card-gray-small {
	background-color: $gray-200;
	// box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.2);
	border-radius: 5px;
	.card-title {
		color: $black;
	}
}

.card-gray {
	padding: 15px 0px 30px;
	min-height: 250px;
	height: 250px;
	justify-content: space-between;
}
