// @import "../vendor/nice-select/css/nice-select.css";

* {
	outline: none;
	padding: 0;
	scroll-behavior: smooth;

	&::after {
		margin: 0;
		padding: 0;
	}

	&::before {
		margin: 0;
		padding: 0;
	}
}

body {
	// font-family: $pp;
	// font-size: 16px;
	// color      : $tl;
	line-height: 1.75;
	// font-weight: 300;
	&.fixed {
		overflow: hidden;
		position: fixed;
	}

	&.dashboard-bg {
		// background: #f9f9f9;
	}
}

ul {
	padding: 0;
	margin: 0;
}

li {
	list-style: none;
}

a {
	// color       : $td;
	text-decoration: none;
	outline: none;

	&:hover,
	&:focus,
	&.active {
		text-decoration: none;
		outline: none;
		color: $primary;
	}
}

#main-wrapper-embed {
	background-color: #ffffff;
	transition: all 0.25s ease-in;
	position: relative;
	z-index: 1;
}

#main-wrapper {
	display: flex;
	flex-direction: column;
	background-color: #ffffff;
	// opacity: 0;
	transition: all 0.25s ease-in;
	// overflow: hidden;
	position: relative;
	z-index: 1;
	// height     : 100vh;
	min-height: 100vh;
	padding-top: 64px;
	// margin-top: 100px;
	@include respond('tab-port') {
		padding-top: 54px;
	}

	&.show {
		opacity: 1;
	}
}

.scroll-locked {
	#main-wrapper {
		max-height: 100vh;
		overflow: hidden;

		.bottom,
		.footer {
			margin-top: 100vh !important;
		}
	}
}

.content-body {
	background: $light;
	padding-top: 15px;
	min-height: calc(100vh - 198px);
	transition: all 0.3s ease;

	@include respond('tab-port') {
		margin-left: 0px;
	}
	.quote-mode {
		margin: 0 auto;
		padding: 0 !important;
	}
}

.container-fluid {
	max-width: 95%;

	&.narrow {
		max-width: 1140px;
	}

	&.landing {
		max-width: 100%;
	}

	@include respond('tab-port') {
		max-width: 100%;
		padding-left: 0px;
		padding-right: 0px;
		margin-right: auto;
		margin-left: auto;
	}
}

.tab-content > .tab-pane {
	background-color: #ffffff;
}
