.toast {
	.toast-time-progress {
		height: 5px;
		background: linear-gradient(90deg, #ffb100 0.02%, #ffde00 138.99%);
		animation: toast-time-progress-animation var(--toast-timeout, 5s) linear 1
			forwards;
	}
}

@keyframes toast-time-progress-animation {
	0% {
		width: 100%;
	}

	100% {
		width: 0%;
	}
}
