.dictionary {
    scroll-behavior: smooth;

    .section {
        .anchor {
            display: block;
            position: relative;
            top: -120px;
            visibility: hidden;
        }
    }

    a {
        color: #869ab8;
        text-decoration: underline;
    }

    .section,
    .anchor-links {
        p {
            color: #869ab8;
            text-decoration: underline;
        }
    }

    .anchor-links {
        display: flex;
        justify-content: center;
    }

    .back-to-top {
        position: fixed;
        bottom: 40px;
        right: 20px;
        height: 50px;
        padding: 0;
        min-width: 50px;
        border-radius: 50%;
        font-size: 30px;
        background-color: #869ab8;
        color: #ffffff;
        cursor: pointer;
        border: none;
        box-shadow: 0 5px 10px #ccc;
    }
}
