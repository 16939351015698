.btn {
	padding: 10px 30px;
	display: inline-block;
	border-radius: 5px;
	min-width: 80px;
	font-size: 16px;
	font-weight: 600;
	border-radius: 50rem;
	height: fit-content;
	// @include respond("tab-port") {
	//   font-size: 13px !important;
	//   min-width: 0px !important;
	//   padding: 10px 20px !important;
	// }

	&.small {
		padding: 7px 15px;
		font-size: 13px;
		min-width: 50px;
	}

	&.x-small {
		padding: 4px 8px !important;
		font-size: 12px;
		min-width: 40px;
	}

	&.btn-tool-picks {
		min-width: 0px;
		min-height: 0px;
		max-height: 30px;
		padding: 5px 5px;
		font-size: 12px;
		font-weight: 400;
		display: flex;
		align-items: center;
		margin-left: 5px;
	}
}

.btn:disabled {
	pointer-events: none;
}

.btn-primary-shadow {
	background-color: $primary;
	color: $white;
	box-shadow: 0px 4px 0px 0px #002A69;
	&:hover,
	&:active {
		background-color: darken($primary, 10%);
	}
}

.btn-white {
	background-color: $white;
	color: $black;
	&:hover,
	&:active {
		color: $headings-color;
	}
}

.btn-black {
	background-color: $black;
	color: $white;
	&:hover,
	&:active {
		color: $white;
		opacity: 0.9;
	}
}

.btn-gold {
	background-color: rgb(225, 180, 92);
	color: $black;
	&:hover,
	&:active {
		background-color: darken(rgb(225, 180, 92), 10%);
		color: $black;
	}
}

.btn-light-gold {
	background-color: rgb(250, 243, 228);
	&:hover,
	&:active {
		background-color: darken(rgb(250, 243, 228), 10%);
	}
}

.btn-outline-gold {
	border: 1px solid #FFB636;
	color: #FFB636;
	&:hover,
	&:active {
		background-color: #FFB636;
		color: $white;
	}
}

.btn-success-light {
	background-color: lighten($success, 45%);
	color: $success;
	&:hover,
	&:active {
		background-color: lighten($success, 40%);
		color: $success;
	}
	&.no-hover {
		background-color: lighten($success, 45%);
		color: $success;
	}
}

.btn-gray {
	background-color: $gray-500;
	color: $white;
	&:hover,
	&:active {
		color: $gray-200;
		opacity: 0.9;
	}
}

.btn-light-gray {
	background-color: $gray-300;
	color: $black;
	border-radius: 10px;
	&:hover {
		color: $black;
		background-color: $gray-400;
	}
	&:active {
		color: $gray-200;
	}
}

.btn-lighter-gray {
	background-color: $gray-100;
	color: $black;
	border-radius: 10px;
	&:hover {
		color: $black;
		background-color: $gray-200;
	}
	&:active {
		color: $gray-200;
	}
}

.btn-light-primary {
	background-color: lighten($primary, 45%);
	color: $black;
	&:hover {
		background-color: lighten($primary, 40%);
		color: $black;
	}
	&:active {
		color: $black;
	}
}

.btn-alt-primary {
	background-color: lighten($primary, 45%);
	color: $primary;
	border-radius: 5px;
	font-weight: 500;
	&:hover {
		background-color: lighten($primary, 40%);
		color: $primary;
	}
	&:active {
		background-color: lighten($primary, 40%);
	}
}

.btn-alt-danger {
	border: 1px solid $danger;
	color: $danger;
	border-radius: 5px;
	font-weight: 500;
	&:hover {
		background-color: darken($white, 5%);
		color: $danger;
	}
	&:active {
		background-color: darken($white, 5%);
	}
}

.btn-link {
	min-width: 0px;
	font-weight: 400 !important;
	padding: 0 !important;
	min-width: 'initial' !important;
	background-color: transparent !important;
	text-decoration: underline !important;
	&.text-decoration-none {
		text-decoration: none !important;
	}
}

#___reactour {
	button,
	.btn {
		min-width: 0px;
	}
}

.screener-form-row {
	&.active {
		background-color: lighten($primary, 45%);
	}

	button,
	.btn {
		min-width: 0px;
	}
}

@keyframes glowing {
	0% {
		background-color: #678bea;
		box-shadow: 0 0 3px #678bea;
		border: transparent;
	}
	50% {
		background-color: #1652f0;
		box-shadow: 0 0 10px #1652f0;
		border: transparent;
	}
	100% {
		background-color: #678bea;
		box-shadow: 0 0 3px #678bea;
		border: transparent;
	}
}

.button-glowing {
	animation: glowing 1000ms infinite;
	color: white;
}

.nav-pills {
	.nav-link {
		@include respond('tab-port') {
			font-size: 13px !important;
			min-width: 0px !important;
			padding: 5px 10px !important;
			font-weight: 600;
		}
	}
}

.rounded-button {
	border-radius: 28px;
	max-width: 80%;
	&.rounded-button-white {
		background-color: $white;
		box-shadow: 2px 3px 3px 0px darken($primary, 20%);
		&:hover,
		&:active {
			box-shadow: 2px 3px 18px 0px darken($primary, 22%);
			color: $primary;
		}
	}
	&.rounded-button-blue {
		background-color: $primary;
		box-shadow: 2px 3px 3px 0px $gray-500;
		color: $white;
		&:hover,
		&:active {
			color: $gray-400;
		}
	}
}

.btn-none:not(.btn-primary) {
	border: none;
	background-color: transparent;
}

.btn:focus,
.btn:active {
	outline: none !important;
	box-shadow: none !important;
}

.btn-multi-animated {
	color: $primary;
	box-sizing: border-box;
	position: relative;
	padding: 10px 20px;
	background-clip: padding-box;
	border: 2px solid transparent;
	border-radius: 50px;
	z-index: 2;
	transition: opacity 0.3s cubic-bezier(0.165, 0.84, 0.64, 1);
	&.minizied {
		padding: 5px 10px;
		border-radius: 50px;
	}
	&::after {
		content: '';
		box-sizing: border-box;
		margin: 0px;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: -1;
		border-radius: 50px;
		background-color: #fff;
	}
	&:before {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: -2;
		margin: -2px;
		border-radius: 50px;
		background: radial-gradient(
			95.44% 7781.23% at 97.56% 13.23%,
			#0500ff 0%,
			rgba(255, 0, 229, 0.708333) 29.17%,
			#0500ff 74.48%,
			#ff0000 100%
		);
	}
}

.btn-multi-animated:hover {
	color: $primary;
	opacity: 0.7;
}

.btn-bb {
	position: relative;
	overflow: hidden;
	padding: 0.25rem 0.75rem;
	border: 1px solid $gray-500;
	margin-left: 0.5rem;
	transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);

	.highlight {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		background-color: $primary;
		opacity: 0.2;
		transition: inherit;
	}
}

.btn-bb:first-of-type {
	margin: 0;
}

.btn-bb:hover,
.btn-bb.active {
	border: 1px solid $primary;
	box-shadow: 0 0 1px 0.5px $primary;
}

.btn-outline {
	border: 1px solid $gray-500;
}

.btn-group {
	.btn-gray:not(.active) {
		background-color: transparent;
		color: $gray-500;
		border: 1px solid $gray-500;
	}
}

.onboarding-questionnaire-option {
	// .btn {
	// 	width: 100%;
	// 	color: $dark;
	// 	background: $gray-100;
	// 	padding: 4px 8px;
	// 	font-size: 14px;
	// 	min-width: 0px;
	// 	.inner-items-container {
	// 		display: flex;
	// 		justify-content: center;
	// 		align-items: center;
	// 	}
	// }
	&.option-full-width {
		.btn {
			width: 100%;
			img {
				height: 25px;
				width: auto;
				margin-right: 8px;
			}
			font-size: 16px;
			height: 45px;
		}
	}
	&.option-big {
		flex: 1 0 45%; /* Grow to fill the space, don't shrink, and base width is 50% */
		box-sizing: border-box;
		.btn {
			border-radius: 5px;
			img {
				height: 100px;
				width: auto;
				margin-bottom: 4px;
			}
			.title {
				font-size: 16px;
				font-weight: 700;
			}
			.sub-title {
				font-size: 14px;
				font-weight: 400;
			}
			.inner-items-container {
				flex-direction: column;
			}
		}
	}
	// &.active {
	// 	.btn {
	// 		border: 1px solid $primary;
	// 	}
	// }
	// &:hover {
	// 	.btn {
	// 		background: $gray-200;
	// 	}
	// }
}
