.header,
.header-mobile-menu {
	padding: 3px 0px;
	box-shadow: 0 1px 2px hsla(0, 0%, 0%, 0.05), 0 1px 4px hsla(0, 0%, 0%, 0.05),
		0 2px 8px hsla(0, 0%, 0%, 0.05);
	background: $white;
	transition: all 0.3s ease-in-out;
	z-index: 999;
	position: fixed;
	left: 0;
	right: 0;
	top: 0;

	&.header-mobile-menu {
		box-shadow: none;
		z-index: 998;
		position: fixed;
		left: 0;
		top: initial;
		right: 0;
		bottom: 0;
	}

	&.fixed {
		padding: 10px 0px;
		box-shadow: 0px 5px 10px rgba(22, 28, 45, 0.05);
	}

	&.landing-page {
		background-color: darken($primary, 10%);
		border: 0;
		height: 80px;

		@include respond('tab-port') {
			padding: 0px;
			padding-top: 6px;
			height: 59px;
		}
	}

	.navigation {
		// text-align: right;
		.navbar {
			background-color: transparent !important;
			padding: 0px;

			// .collapse {
			//   justify-content: flex-end;
			// }
			.navbar-nav {
				align-items: center;

				@include respond('tab-port') {
					margin-top: 15px;
				}

				& > .nav-item {
					display: inline-block;
					padding: 7px;

					// margin-left: 15px;
					@include respond('tab-port') {
						width: 100%;
						border-bottom: 1px solid $border-color;
						margin: 0 15px;
						padding: 7px 15px;
						// background: $primary;
					}

					& > a,
					.link-label {
						font-size: 16px;
						font-weight: 600;
						// color: $white;
						color: #506690;

						// font-weight: 600;
						&:hover,
						&:focus,
						&.active {
							// color: $td;
							// @include respond('tab-port') {
							//     color: $white;
							// }
						}

						@include respond('tab-port') {
							&::after {
								position: absolute;
								right: 15px;
								top: 25px;
							}
						}
					}
				}

				@media only screen and (min-width: 991px) {
					.dropdown {
						& > a {
							position: relative;
							transition: all 0.2s ease-in-out;
							// color: $gray-700;
							// &::before{
							//     content: '';
							//     display: block;
							//     position: absolute;
							//     bottom: -19px;
							//     left: 1em;
							//     height: 0;
							//     width: 0;
							//     border: 10px solid transparent;
							//     border-bottom-color: lighten($primary, 40%);
							//     z-index: 999;
							//     transition: all .2s ease-in-out;
							//     opacity: 0;
							// }
						}
					}

					.dropdown-menu {
						background: $white;
						padding: 15px 0;
						display: none;
						opacity: 0;
						visibility: hidden;
						z-index: 1030;
						transition: all 0.2s ease-in-out;
						box-shadow: 0 1.5rem 4rem rgba(22, 28, 45, 0.15);
						border: 0px;
						margin-top: 10px;
						z-index: 998;
						min-width: 10rem;

						a {
							display: inline-block;
							color: $body-color;

							&:hover,
							&:focus,
							&.active {
								color: $body-color;
							}
						}
					}

					.nav-item.dropdown {
						&:hover {
							a {
								&::before {
									opacity: 1;
									bottom: -9px;
								}
							}

							.dropdown-menu {
								display: block;
								opacity: 1;
								visibility: visible;
								// transform: translate3d(-50%,0,0);
								margin-top: 0px;
							}
						}
					}
				}

				@include respond('tab-port') {
					.dropdown-menu {
						border: 0px;
						padding: 0px;

						a {
							border-bottom: 1px solid $border-color;
							padding: 15px;
							color: $body-color;

							// background: #323232;
							&:last-child {
								border: 0px;
							}

							i,
							h6 {
								color: $primary;
							}

							p {
								color: $body-color;
								margin-bottom: 0;
							}
						}
					}
				}
			}
		}
	}
}

.signin-btn-container {
	.btn {
		padding: 7px 15px !important;
		// color: $white ;
		min-width: 0px !important;

		@include respond('tab-port') {
			padding: 5px 10px !important;
			font-size: 13px;
			font-weight: bold;
		}
	}

	@include respond('tab-port') {
		// position: absolute;
		// right: 73px;
		// top: 3px;
	}
}

.signin-btn-style {
	padding: 7px 15px;
	min-width: 0px;

	@include respond('tab-port') {
		padding: 5px 10px;
		font-size: 13px;
		font-weight: bold;
	}
}

.desktop-display {
	@include respond('tab-port') {
		display: none;
	}
}

.phone-display {
	display: none;

	@include respond('tab-port') {
		display: initial;
	}
}

.main-searchbox {
	width: 100%;
	width: -moz-available;
	/* WebKit-based browsers will ignore this. */
	width: -webkit-fill-available;
	/* Mozilla-based browsers will ignore this. */
	width: stretch;

	&.searchbox-main-header {
		max-width: 250px;
	}
	&.searchbox-dashboard-header {
		max-width: 340px;
	}
}

.searchbox-mobile-modal {
	min-height: 80vh;
}

.options-left .react-select__menu {
	left: auto !important;
	right: 0;
	transform-origin: top left;
}

.cursor-pointer {
	cursor: pointer;
}

.text-primary-hover {
	color: $dark;
	&:hover {
		color: $primary !important;
	}
}

.cursor-auto {
	cursor: auto !important;
}

.header-search-icon {
	display: flex;
	flex: auto;
	font-size: 20px;
}

.header-logo {
	@include respond('tab-port') {
		max-width: 100px !important;
	}
}

.navbar-toggler {
	border-color: initial;
	border: none;
	outline: none;
	padding: initial;
}

.login-header {
	padding: 7px;
	color: $primary;
	cursor: pointer;

	@include respond('tab-port') {
		padding: 0px;
		font-size: 13px;
	}
}

.rank-banner {
	z-index: 999;
	position: relative;
	top: -3px;
	left: 0;
	width: 100%;

	@include respond('tab-port') {
		width: calc(100% + 50px);
		left: -25px;
		top: -6px;
	}
}
