.demo_img {
  text-align: center;
  margin-bottom: 60px;

  .img-wrap {
    // max-height: 175px;
    overflow: hidden;
    margin-bottom: 15px;
    box-shadow: 0px 36px 48px rgba(31, 66, 135, 0.04);
    background: lighten($color: $primary, $amount: 45%);
    padding: 20px;
    border-radius: 5px;
  }

  img {
    border-radius: 5px;
  }
}

.intro-demo_img {
  // box-shadow: 0px 20px 25px rgba(22, 28, 45, 0.05);
  box-shadow: 0px 36px 48px rgba(31, 66, 135, 0.04);

  img {
    border-radius: 5px;
  }

}

.dark-view {
  background: #394247;

  h2 {
    color: #fff;
  }

  .img-wrap {
    background: rgba(0, 0, 0, 0.15);
  }

  h4 {
    color: #fff;
  }
}