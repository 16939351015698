.ebook-intro {
    background-color: darken($primary, 10%);
    color: $white;

    .ebook-intro-content {
      h1 {
        // font-weight: 400;
        line-height: 55px;
        margin-bottom: 30px;
        // strong {
        //     font-weight: 500;
        // }
      }
  
      p {
        font-size: 20px;
        margin-bottom: 30px;
        margin-top: 30px;
      }
    }

    .form-control {
        background-color: $primary;
        color: $white;
        border-color: darken($primary, 5%);
    }

    .privacy {
        font-size: 12px;
    }
  }

.background-breaker {
    height: 300px;
    background: linear-gradient(175deg, darken($primary, 10%) 50%, $white 50%)
}

.ebook-section {
    padding: 50px 0;
    
    .portfolio_list .port-icon {
        color: darken($primary, 10%);
    }
}