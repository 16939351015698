.main-leaderboard-progress {
	overflow: hidden;
	position: relative;
	height: 100px;
	width: 100px;
	transform-origin: center;
	transform: rotateZ(-90deg);

	svg {
		circle#bar {
			transition: stroke-dashoffset 1s cubic-bezier(0.165, 0.84, 0.44, 1);
		}
	}

	h4 {
		position: absolute;
		top: 50%;
		left: 50%;
		margin: 0;
		transform: translate(-35%, -65%) rotateZ(90deg);
	}
}
