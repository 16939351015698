.header {
	@include respond('tab-port') {
		padding: 6px 25px;
	}

	&.dashboard {
		&.tour {
			pointer-events: none;
		}

		@include respond('tab-port') {
			margin-left: 0px;
		}

		.navbar {
			padding: 0px;
		}
	}

	.navbar-brand {
		// margin-right: 30px;
		display: flex;
		align-items: center;
		margin-right: 0.5rem;

		img {
			max-width: 115px;
		}

		// @include respond("tab-land") {
		//   margin-right: 30px;
		// }

		span {
			color: $dark;
			font-weight: 700;
			margin-left: 20px;
			font-size: 24px;
		}
	}

	// &.fixed {
	//     position: fixed;
	//     background: $primary;
	//     transition: all 0.3s ease-in;
	// }
}

.header_auth {
	.btn {
		margin-left: 10px;

		@include respond('tab-port') {
			padding: 7px;
			min-width: 110px;
		}

		@include respond('tab-port') {
			display: none;
		}
	}
}

.navbar-toggler {
	background: rgba(255, 255, 255, 0.2);
	min-width: auto;
}

.navbar-nav {
	@include respond('tab-port') {
		// background: darken($primary, 10%);
		margin-top: 10px;
		border-radius: 5px;
		// padding: 10px 0px;
	}

	.nav-item {
		.nav-link {
			// padding: 0 20px;
			// color: $dark!important;
			font-weight: 600;

			// opacity: 0.75;
			// @include respond('tab-port') {
			//     padding: 10px 20px !important;
			// }
			// @include respond('tab-land') {
			//     padding: 10px 15px !important;
			// }
			&:hover,
			&:focus,
			&.active {
				opacity: 1;
			}
		}

		// &.active {
		//     .nav-link {
		//         color: $white !important;
		//         @include respond('tab-port') {
		//             color: $white !important;
		//         }
		//     }
		// }
	}
}

// .dashboard_log {
//     @include respond('tab-port') {
//         position: absolute;
//         right: 0px;
//         top: 15px;
//         margin: 0px !important;
//     }
// }

// .dashboard_log {}
.account_money {
	margin-right: 20px;

	@include respond('phone-land') {
		display: none;
	}

	ul {
		li {
			display: inline;
			justify-content: space-around;

			&.crypto {
				background: lighten($primary, 45%);
				padding: 8px 15px 8px 22px;
				color: $primary;
				border-radius: 30px 0 0px 30px;
				border: 1px solid lighten($primary, 45%);
				transition: all 0.3s ease-in-out;

				@include respond('tab-land') {
					padding: 8px 10px 8px 20px;
				}

				@include custommq($min: 1200px, $max: 1350px) {
					padding: 8px 10px 8px 20px;
				}

				span {
					margin-right: 10px;
					font-weight: 600;

					@include respond('tab-land') {
						margin-right: 10px;
					}

					@include custommq($min: 1200px, $max: 1350px) {
						margin-right: 10px;
					}
				}

				&:hover,
				&:focus,
				&.active {
					background: $primary;
					color: $white;
					border: 1px solid $primary;
				}
			}

			&.usd {
				border: 1px solid $border-color;
				padding: 8px 20px 8px 10px;
				border-radius: 0px 30px 30px 0px;

				@include respond('tab-land') {
					padding: 8px 20px 8px 10px;
				}

				@include custommq($min: 1200px, $max: 1350px) {
					padding: 8px 20px 8px 10px;
				}

				span {
					font-weight: 600;
					color: $body-color;
				}
			}
		}
	}
}

.profile_log {
	cursor: pointer;

	.dropdown-toggle {
		font-weight: 600;
		color: $body-color !important;
		background-color: transparent !important;
		border-color: transparent !important;
		box-shadow: none !important;
		padding-left: 0px !important;
		padding-right: 0px !important;
		min-width: auto !important;
	}

	.btn-primary:not(:disabled):not(.disabled):active,
	.btn-primary:not(:disabled):not(.disabled).active,
	.show > .btn-primary.dropdown-toggle {
		color: $body-color !important;
		background-color: transparent !important;
		border-color: none !important;
		box-shadow: none !important;
	}

	.user {
		display: flex;
		align-items: center;

		.thumb {
			height: 45px;
			width: 45px;
			border-radius: 50px;
			border: 1px solid $border-color;
			display: inline-block;
			margin-right: 15px;
			background: $primary;
			color: $white;
			text-align: center;
			padding: 10px 0;

			i {
				font-size: 20px;
			}
		}

		.name {
			margin-right: 15px;
			// font-size: 18px;
			font-weight: 600;

			// color: $white;
			@include respond('tab-land') {
				display: none;
			}

			@include custommq($min: 1200px, $max: 1350px) {
				display: none;
			}
		}

		.arrow {
			// color: $white;
			i {
				font-weight: bold;
				font-size: 16px;
				line-height: 16px;
				margin-top: 6px;
				display: inline-block;
			}

			@include respond('tab-land') {
				display: none;
			}
		}
	}

	.dropdown-menu {
		border: 0px;
		padding: 0px;
		margin: 0px;
		top: 30px !important;
		width: 200px;
		box-shadow: 0 1.5rem 4rem rgba(22, 28, 45, 0.15);
		border-radius: 5px;
		background-color: #fff;

		.dropdown-item {
			padding: 15px 20px;
			border-bottom: 1px solid $border-color;
			font-size: 16px;
			color: $body-color;
			font-weight: 600;

			&:last-child {
				border: 0px;
			}

			i {
				margin-right: 10px;
				font-size: 22px;
				color: $primary;
				font-weight: bold;
			}

			&:hover,
			&:focus,
			&.active {
				background-color: initial !important;
			}
		}
	}
}

.dropdown-item {
	&.active {
		background-color: transparent;
	}
}

.logout {
	color: $danger !important;

	i {
		color: $danger !important;
	}
}

.profile-card-extra {
	.dropdown-toggle {
		padding: 0rem !important;
		&::after {
			border: none !important;
		}
	}
}

.account-element {
	display: flex;
	align-items: center;
	.dropdown-toggle {
		display: flex;
		align-items: center;
		padding: 0rem !important;
		width: 40px;
		&::after {
			border: none !important;
		}
	}
	.dropdown-menu {
		left: -360px;
		width: 460px;
		max-width: 460px;

		@include respond('phone') {
			left: -150px;
			width: 260px;
			max-width: 260px;
		}
	}
}
