.comps-finder{
  min-height: calc(100vh - 160px)
}

.show-description-icon{
  text-align: center;
}

.comps-finder-search-box{
  width:450px;
}

.comps-finder-buttons-group{
  display: flex; 
  justify-content: space-evenly;
  margin-top: 10px;
  @include respond("phone-land") {
    flex-direction: column;
  }
}