.preloader {
  position: fixed;
  width: 100%;
  height: 100%;
  // background-color: #FFF;
  background-color: inherit;
  z-index: 999;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  &.non-fixed {
    position: initial;
  }
}

.element-loader {
  background-color: inherit;
  z-index: 0;
  .preloader {
    position: absolute;
    z-index: 0;
    height: calc(100% + 4px);
    width: calc(100% + 4px);
  }
}

.infinite-loader .preloader {
  position: unset;
  height: calc(100% + 4px);
  width: calc(100% + 4px);
}