.nav-tabs {
	margin-bottom: 30px;
	border-bottom: 1px solid #f1f4f8;

	.nav-item {
		text-align: center;
		padding: 10px 10px;
		font-size: 1rem;
		// text-transform: uppercase;
		border: 0px;
		font-weight: 500;
		// border-radius: 5px;
		background: transparent;
		// border: 1px solid $border-color;
		margin: 0px;

		&.active {
			background: $primary;
			color: white;
		}

		&.nav-link:hover {
			background: $primary;
			color: white;
			transition: all 0.3s ease;
		}
	}
}

.fixed-table {
	// overflow-x: scroll;
	// overflow-y: visible;
	position: relative;
	overflow: auto;

	thead th {
		font-weight: bold;
	}

	th {
		font-weight: 400;
		min-width: 85px;
	}

	td {
		color: #000000;
	}

	th,
	td {
		padding: 0.5rem;
	}

	.sticky-col {
		background-color: $white;
		position: sticky;
		position: -webkit-sticky;
		top: 0;
		z-index: 99;
	}

	.reg-col {
		min-width: 110px;
	}

	.first-col {
		// position: absolute;
		// width: 450px;
		// min-width: 450px;
		// max-width: 450px;
		left: 0;
		// @include respond('phone-land') {
		//   width: 100px;
		//   min-width: 100px;
		//   max-width: 100px;
		// }
	}

	.second-col {
		// position: absolute;
		// width: 100px;
		// min-width: 100px;
		width: 45px !important;
		min-width: 0px !important;
		left: 85px;
	}

	.btn {
		min-width: unset;
		padding: 0.375rem 0.75rem;
	}

	.sticky-row {
		th {
			position: sticky;
			position: -webkit-sticky;
		}

		th:nth-child(-n + 2) {
			z-index: 3;
		}
	}

	.first-row {
		th {
			top: 0;
		}
	}

	.second-row {
		th {
			top: 36px;
		}
	}
}
