.auth-modal-circle {
	position: absolute;
	border-radius: 50%;
	width: 140px;
	height: 140px;
	display: flex;
	align-items: center;
	justify-content: center;
	white-space: pre-wrap;
	text-align: center;
	transition: transform 1.35s cubic-bezier(0.16, 0.01, 0.83, 0.99);

	h4 {
		color: $white;
		margin: 0;
	}

	&.light-blue {
		z-index: 3;
		top: -50px;
		left: -70px;
		background-image: linear-gradient(
			220deg,
			rgba(153, 237, 255, 0.5) 14.85%,
			rgba(0, 209, 255, 0.5) 86.54%
		);
	}
	&.blue {
		z-index: 2;
		top: 25px;
		left: 4px;
		background-image: linear-gradient(
			132deg,
			rgba(0, 70, 255, 0.9) 15.2%,
			rgba(0, 70, 255, 0.67) 84.61%
		);
	}
	&.purple {
		z-index: 1;
		top: 52px;
		left: -100px;
		background-image: linear-gradient(
			156deg,
			rgba(159, 157, 255, 0.5) 13.93%,
			rgba(61, 0, 233, 0.5) 86.59%
		);
	}
}
