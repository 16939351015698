.docs-info {
	h2 {
		font-size: 24px;
	}
}

.embed-logo {
	z-index: 100;
	background: $white;
	top: 5px;
	right: -160px;
	transition: right 0.5s;
	color: #000;
	font-size: 15px;
	&:hover {
		right: 0px;
		color: #000;
	}
}

.widgets-header {
	h1 {
		font-size: 80px;
		font-weight: 600;
		margin-bottom: 0.5rem;
		line-height: 80px;
		padding-bottom: 24px;

		@include respond('tab-port') {
			font-size: 56px;
			line-height: 56px;
			padding-bottom: 12px;
		}

		@include respond('phone') {
			font-size: 40px;
			line-height: 40px;
			padding-bottom: 12px;
		}
	}

	p {
		font-size: 28px;
		font-weight: 400;
		margin-bottom: 1.5rem;
		line-height: 40px;
		color: #000;
		@include respond('tab-port') {
			font-size: 24px;
			line-height: 32px;
			margin-bottom: 0.75rem;
		}

		@include respond('phone') {
			font-size: 18px;
			line-height: 28px;
			margin-bottom: 0.75rem;
		}
	}

	padding: 110px 0;
	@include respond('tab-port') {
		padding-top: 30px 0;
	}
}

.widgets-info {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 0 0 80px 0;
	width: 100%;
	@include respond('tab-port') {
		margin: 30px 0;
	}

	.widgets-info-preview {
		img {
			width: auto !important;
		}
		margin-bottom: 48px;
		@include respond('tab-port') {
			margin-bottom: 24px;
		}
	}

	h2 {
		text-align: center;
		font-size: 36px;
		font-weight: 600;
		margin-bottom: 40px;

		@include respond('tab-port') {
			font-size: 24px;
			margin-bottom: 16px;
			padding: 0 10px;
		}
	}

	p {
		font-size: 18px;
		font-weight: 400;
		margin-bottom: 24px;
		color: #000;
		text-align: center;
		max-width: 800px;
		@include respond('tab-port') {
			font-size: 16px;
			margin-bottom: 16px;
			padding: 0 10px;
		}
	}
}

.widgets-generator {
	display: flex;
	flex-direction: column;
	margin: 80px 0;
	@include respond('tab-port') {
		margin: 30px 0;
	}

	h2 {
		font-size: 48px;
		font-weight: 600;
		margin-bottom: 24px;
		text-align: center;
		@include respond('tab-port') {
			font-size: 24px;
			margin-bottom: 12px;
		}
	}

	h3 {
		font-size: 28px;
		line-height: 28px;
		font-weight: 600;
		margin: 12px;
		color: #000;
		@include respond('tab-port') {
			font-size: 20px;
			text-align: center;
		}
	}

	h4 {
		font-size: 24px;
		line-height: 24px;
		font-weight: 400;
		margin-bottom: 48px;
		color: #000;
		text-align: center;
		@include respond('tab-port') {
			font-size: 16px;
			margin-bottom: 24px;
		}
	}
}

.widget-premium-config-title {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-bottom: 24px;
	@include respond('tab-port') {
		margin-bottom: 12px;
	}
	h4 {
		color: #000;
		text-align: center;
		margin-bottom: 0px;
		font-size: 20px;
		font-weight: 600;
		@include respond('tab-port') {
			font-size: 16px;
		}
	}
	.toggle {
		margin-bottom: 0px !important;
		margin-right: 12px;
	}
}

.widget-faq-title,
.widget-pricing-title {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 80px;
	width: 100%;
	@include respond('tab-port') {
		margin-top: 40px 0;
	}

	h2 {
		font-size: 48px;
		font-weight: 600;
		margin-bottom: 24px;
		text-align: center;
		@include respond('tab-port') {
			font-size: 24px;
			margin-bottom: 12px;
		}
	}

	h4 {
		font-size: 24px;
		line-height: 24px;
		font-weight: 400;
		margin-bottom: 48px;
		color: #000;
		text-align: center;
		@include respond('tab-port') {
			font-size: 16px;
			margin-bottom: 24px;
		}
	}
}

.bg-gray {
	background-color: $gray-400 !important;
}

.bg-light-gray {
	background-color: $gray-300 !important;
}

.bg-lighter-gray {
	background-color: $gray-100 !important;
}

.bg-fb {
	background-color: #2d4a9d !important;
}

.bg-light-gold {
	background-color: rgb(250, 243, 228) !important;
}

.bg-light-success {
	background-color: #cfffd9;
}

.bg-light-danger {
	background-color: #ffdbdb;
}

.widget-selection-item {
	min-height: 120px;
	// background-color: lighten($primary, 45%);
	border-radius: 10px;

	&:hover {
		border: 1px solid $primary;
		background-color: darken($gray-100, 5%);
	}
}

.pricing {
	.period-toggle {
		color: #000;
		margin-bottom: 3rem;
		display: flex;
		align-items: center;
		font-size: 24px;

		@include respond('phone') {
			font-size: 18px;
			margin-bottom: 1.5rem;
		}
	}

	h3 {
		font-size: 4rem;

		@include respond('phone') {
			font-size: 3rem;
		}
	}
}

.testimonials-logos-container {
	overflow: hidden;
	margin-bottom: 30px;

	.banner {
		width: 2376px;

		img {
			height: 36px;
			margin-right: 16px;
			-webkit-transition: all 1s ease;
			-moz-transition: all 1s ease;
			-o-transition: all 1s ease;
			-ms-transition: all 1s ease;
			transition: all 1s ease;
		}
	}

	.first {
		-webkit-animation: bannermove 50s linear infinite;
		-moz-animation: bannermove 50s linear infinite;
		-ms-animation: bannermove 50s linear infinite;
		-o-animation: bannermove 50s linear infinite;
		animation: bannermove 50s linear infinite;
	}	  
	@keyframes bannermove {
		0% {
		  margin-left: 0px;
		}
		100% {
		  margin-left: -1340px;
		}
	  }
	  
	  @-moz-keyframes bannermove {
		0% {
		  margin-left: 0px;
		}
		100% {
		  margin-left: -1340px;
		}
	  }
	  
	  @-webkit-keyframes bannermove {
		0% {
		  margin-left: 0px;
		}
		100% {
		  margin-left: -1340px;
		}
	  }
	  
	  @-ms-keyframes bannermove {
		0% {
		  margin-left: 0px;
		}
		100% {
		  margin-left: -1340px;
		}
	  }
	  
	  @-o-keyframes bannermove {
		0% {
		  margin-left: 0px;
		}
		100% {
		  margin-left: -1340px;
		}
	  }
}
	